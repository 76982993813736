import React from 'react';
import { buildWall, updatedWall } from './wall-factory-3d';
import * as SharedStyle from '../../shared-style';
import * as Geometry from '../../utils/geometry';
import Translator from '../../translator/translator';
import { LINE_THICKNESS } from '../../../src/constants';

const epsilon = 20;
const STYLE_TEXT = { textAnchor: 'middle' };
const STYLE_LINE = { stroke: SharedStyle.LINE_MESH_COLOR.selected };
const STYLE = {fill: "#0096fd", stroke: SharedStyle.COLORS.white, cursor: "move"};
const STYLE_POLYGON = {
  strokeWidth: 1,
  strokeLinejoin: 'round',
  stroke: SharedStyle.LINE_MESH_COLOR.unselected,
  rx: '2px',
  fill: SharedStyle.LINE_MESH_COLOR.unselected
};
const STYLE_POLYGON_SELECTED = {
  strokeWidth: 1,
  strokeLinejoin: 'round',
  rx: '2px',
  stroke: SharedStyle.LINE_MESH_COLOR.selected,
  fill: SharedStyle.LINE_MESH_COLOR.selected
};

let translator = new Translator();

export default function WallFactory(name, info, textures) {

  let wallElement = {
    name,
    prototype: 'lines',
    info,
    height: {
      label: translator.t('height'),
      type: 'length-measure',
      defaultValue: {
        length: 243.84,
      }
    },
    thickness: {
      label: translator.t('thickness'),
      type: 'length-measure',
      defaultValue: {
        length: LINE_THICKNESS / 2
      }
    },
    properties: {
    },

    render2D: function (element, layer, scene) {
      let { x: x1, y: y1 } = layer.vertices.get(element.vertices.get(0));
      let { x: x2, y: y2 } = layer.vertices.get(element.vertices.get(1));
      let length = Geometry.pointsDistance(x1, y1, x2, y2);
      let length_5 = length / 5;
      let thickness = LINE_THICKNESS;
      // let thickness = element.getIn(['properties', 'thickness', 'length']);
      let half_thickness = thickness / 2;
      let half_thickness_eps = half_thickness + epsilon;
      let char_height = 11;
      let extra_epsilon = 5;
      let textDistance = half_thickness + epsilon + extra_epsilon;
      let related0x = x1, related0y = y1, related1x = x2, related1y = y2;
      element.relatedVertices.forEach((relatedVertice, index)=>{
        if(relatedVertice.index == undefined)
          relatedVertice = relatedVertice.toJSON();
          
        if(relatedVertice.index == 0){
          related0x = relatedVertice.point.x;
          related0y = relatedVertice.point.y;
        }else
        {
          related1x = relatedVertice.point.x;
          related1y = relatedVertice.point.y;
        }
      });

      return (!element.selected) ?
        <g>
          <polygon points={`${x1},${y1} ${related0x},${related0y} ${related1x},${related1y} ${x2},${y2}`} style={STYLE_POLYGON} ></polygon>
        </g> :
        <g>  
          <polygon points={`${x1},${y1} ${related0x},${related0y} ${related1x},${related1y} ${x2},${y2}`} style={STYLE_POLYGON_SELECTED} ></polygon>
        </g>  
    },

    render3D: function (element, layer, scene, mode) {
      return buildWall(element, layer, scene, textures, mode);
    },

    updateRender3D: (element, layer, scene, mesh, oldElement, differences, selfDestroy, selfBuild) => {
      return updatedWall(element, layer, scene, textures, mesh, oldElement, differences, selfDestroy, selfBuild);
    }

  };

  if (textures && textures !== {}) {

    let textureValues = { 'none': 'None' };

    for (let textureName in textures) {
      textureValues[textureName] = textures[textureName].name;
    }

/*    wallElement.properties.textureA = {
      label:'Fronts ' + translator.t('texture'),
      type: 'enum',
      defaultValue: textureValues.bricks ? 'bricks' : 'none',
      values: textureValues
    };
*/
    wallElement.properties.textureB = {
      //label:'Back ' + translator.t('texture'),
      label: 'Walls',
      type: 'enum',
      defaultValue: textureValues.bricks ? 'bricks' : 'none',
      values: textureValues
    };

  }

  return wallElement;
}
