import React from 'react';
import PropTypes from 'prop-types';
import GridStreak from './grid-streak';

export default function Grids({scene}) {

  let {width, height, grids} = scene;
  let renderedGrids = grids.entrySeq().map(([gridID, grid]) => {
    return (<GridStreak key={gridID} width={width} height={height} grid={grid}/>);
  }).toList();

  return (<g>{renderedGrids}</g>);
}

Grids.propTypes = {
  scene: PropTypes.object.isRequired
};
