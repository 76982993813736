import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FormNumberInput, FormTextInput } from '../../../style/export';
import { PropertyLengthMeasure } from '../../../../catalog/properties/export';
import styled from 'styled-components';
import { DEFAULT_FONT_FAMILY, TEXT_COLOR_NEUTRAL_2, TEXT_COLOR_NEUTRAL_0, TEXT_COLOR_NEUTRAL_3 } from '../../../../constants';

const NameInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`

const NameInputTitle = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: ${TEXT_COLOR_NEUTRAL_0};
  margin-right: auto;
`

const NumberInputMeasure = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
  color: ${TEXT_COLOR_NEUTRAL_3};
  position: absolute;
  right: 10px;
  bottom: 12px;
`

const tableStyle = { width: '100%' };
const firstTdStyle = { width: '6em' };
const inputStyle = { textAlign: 'left' };

export default function LineAttributesEditor({element, onUpdate, attributeFormData, state, ...rest}, {translator}) {

  let name = attributeFormData.has('name') ? attributeFormData.get('name') : element.name;
  let vertexOne = attributeFormData.has('vertexOne') ? attributeFormData.get('vertexOne') : null;
  let vertexTwo = attributeFormData.has('vertexTwo') ? attributeFormData.get('vertexTwo') : null;
  let lineLength = attributeFormData.has('lineLength') ? attributeFormData.get('lineLength') : null;
  let focus = attributeFormData.has('focus') ? attributeFormData.get('focus') : false;
  let unit = state.getIn(['scene', 'layers', state.scene.selectedLayer, 'unit'])
  return (
    <div>
      <NameInputWrapper>
        <NameInputTitle style={{fontSize:16}}>{translator.t('Name')}</NameInputTitle>
        <FormTextInput
          value={name}
          onChange={event => onUpdate('name', event.target.value)}
        />
      </NameInputWrapper>
      <div style={{position: 'relative'}}>
        <PropertyLengthMeasure
          value={ lineLength }
          onUpdate={mapped => onUpdate('lineLength', mapped)}
          configs={{label: "Length", min: 0, max: Infinity, precision: 2, focus: focus}}
          state={state}
        />
        <NumberInputMeasure>{unit}</NumberInputMeasure>
      </div>
    </div>
  );
}

LineAttributesEditor.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  attributeFormData: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
};

LineAttributesEditor.contextTypes = {
  translator: PropTypes.object.isRequired,
};
