import React from 'react';
import moment from "moment";
import Viewer3DDownLoad from "./viewer3DDownLoad";
import * as constants from "../../../../../constants";

const Show3DView = ({ props }) => {
  return (
    <div style={{ width: '100%', minHeight:'100%', backgroundColor: '#fff', marginBottom: '10px', borderRadius: '5px' }}>
      <Viewer3DDownLoad state={props.state} width={452} height={350}/>
      <div style={{ padding: '10px 30px 30px 30px', fontSize: '8px' }}>
        <div style={{ alignItems: 'center', padding: '5px 0' }}>
          <div style={styles.headerInfo}>
            <div style={{}}>Created for: {sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</div>
            <div style={{}}>Date: {moment(new Date()).format('DD-MM-YYYY')}</div>
            <div style={{}}>ID: #{sessionStorage.getItem('projectID')}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: "11px" }}>
            <div style={{ flex: 3 }}>
              <div style={{ fontSize: 'x-large' }}>{sessionStorage.getItem('projectTitle')}</div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',fontWeight:'bold' }}>
                <span>Summary ID:#{sessionStorage.getItem('projectID')}{moment(new Date()).format('DD-MM-YYYY')}</span>
                <span>Available: {moment(new Date()).format('DD-MM-YYYY')}</span>
              </div>
            </div>
            <div style={{ flex: 2, display: 'flex', justifyContent: 'end' }}>
              <div style={{ height: 65, width: 65 }}>
                <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: '30px 0' }}>
          <div style={{ textAlign: 'justify', margin: '40px 0' }}>
            <b>DisClaimer:</b>DIY Design Space not take resposibility for the
            accuracy of measurements or furniture arrangements. The prices
            displayed here apply to items in stock at the time of the offer's
            issuance. All delivery, assembly, and installation services required
            are billed are billed separately and are not included in the price.
            While we strive to ensure the accuracy of the information in this
            program, we apologize for any errors that may occur.'
          </div>
        </div>
        <div style={{ padding: '15px 0', borderTop: '1px solid gray', display: 'flex', justifyContent: 'space-between', fontFamily: 'sans-serif', color: '#777777' }}>
          <span>＠ DIY.DS v1.0/2024</span>
          <span>DIY Design Space 2024 All rights reserved</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  headerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',
    fontFamily: 'sans-serif',
    color: '#777777',
    borderBottom: '1px solid #878787'
  }
}

export default Show3DView;
