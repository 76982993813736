import React, { useEffect, useRef } from 'react';
import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
import State from '../../../../viewer2d/state';

const Viewer2DDownLoad = ({state, catalog,width, height }) => {

  const Viewer = useRef(null);

  useEffect(() => {
    // move viewer point to center
    let selectedLayer = state.scene.layers.get(state.scene.selectedLayer);
    const vertices = selectedLayer.vertices;

    if (vertices.isEmpty()) {
      return;
    }

    let bottomX = 0,
      bottomY = 100000,
      topX = 100000,
      topY = 0;

    vertices.forEach(vertex => {
      if (bottomX < vertex.x) bottomX = vertex.x;
      if (bottomY > vertex.y) bottomY = vertex.y;
      if (topX > vertex.x) topX = vertex.x;
      if (topY < vertex.y) topY = vertex.y;
    });

    let width = bottomX-topX;
    let height = topY-bottomY;
    let scale = Math.min(393/width, 350/height);
    let moveX = topX + width / 2;
    let moveY = 2000 - (bottomY + height / 2);

    Viewer.current.setPointOnViewerCenter(moveX, moveY, scale * 0.7);
  }, []);

  return (
    <div>
      <ReactSVGPanZoom
        width={width}
        height={height}
        miniaturePosition="none"
        toolbarPosition="none"
        ref={Viewer}
        detectWheel={false}
        detectAutoPan={false}
      >
        <svg width={width} height={height}>
          <defs>
            <pattern id="diagonalFill" patternUnits="userSpaceOnUse" width="4" height="4" fill="#FFF">
              <rect x="0" y="0" width="4" height="4" fill="#FFF" />
              <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style={{ stroke: '#8E9BA2', strokeWidth: 1 }} />
            </pattern>
          </defs>
          <g>
            <State state={state} catalog={catalog} />
          </g>
        </svg>
      </ReactSVGPanZoom>
    </div>
  );

};

export default Viewer2DDownLoad;
