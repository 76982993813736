import { ElementsFactories } from 'KitchenConfigurator';

const info = {
  title: 'wall',
  tag: ['wall'],
  description: 'Wall with bricks or painted',
  image: require('./wall.png'),
  visibility: {
    catalog: true,
    layerElementsVisible: true
  }
};

const textures = {
  plaster: {
    name: 'Plaster',
    uri: require('./textures/plaster.jpg'),
    lengthRepeatScale: 0.005,
    heightRepeatScale: 0.005,
    normal: {
      uri: require('./textures/plaster-normal.jpg'),
      lengthRepeatScale: 0.005,
      heightRepeatScale: 0.005,
      normalScaleX: 0.4,
      normalScaleY: 0.4,
      color: '#000000'
    }
  },
  bricks: {
    name: 'Bricks',
    uri: require('./textures/bricks.jpg'),
    lengthRepeatScale: 0.009,
    heightRepeatScale: 0.009,
    normal: {
      uri: require('./textures/bricks-normal.jpg'),
      lengthRepeatScale: 0.009,
      heightRepeatScale: 0.009,
      normalScaleX: 0.4,
      normalScaleY: 0.4,
      color: '#000000'
    }
  },
  painted: {
    name: 'Painted',
    uri: require('./textures/painted.jpg'),
    lengthRepeatScale: 0.005,
    heightRepeatScale: 0.005,
    normal: {
      uri: require('./textures/painted-normal.jpg'),
      lengthRepeatScale: 0.005,
      heightRepeatScale: 0.005,
      normalScaleX: 0.4,
      normalScaleY: 0.4,
      color: '#000000'
    }
  },
  morden: {
    name: 'Morden',
    uri: require('./textures/morden.jpg'),
    lengthRepeatScale: 0.005,
    heightRepeatScale: 0.005,
    normal: {
      uri: require('./textures/morden-normal.jpg'),
      lengthRepeatScale: 0.005,
      heightRepeatScale: 0.005,
      normalScaleX: 0.4,
      normalScaleY: 0.4,
      color: '#000000'
    }
  }
};

export default ElementsFactories.WallFactory('wall', info, textures);

