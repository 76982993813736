import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles} from '@material-ui/core/styles';
import {
  BG_COLOR_1,
  DEFAULT_FONT_FAMILY,
  STATUS_NEGATIVE_COLOR,
  STATUS_POSITIVE_COLOR,
  STATUS_WARNING_COLOR,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_2
} from '../../../../constants';
import * as S from '../../main/styles';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: TEXT_COLOR_NEUTRAL_0,
    color: BG_COLOR_1,
    maxWidth: 180,
    fontSize: 9,
    fontFamily: DEFAULT_FONT_FAMILY,
    boxShadow: '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)',
    padding: "2px 15px"
  },
}))(Tooltip);

export default function ReviewMolding({ downLoad, molding, moldingSKU, products }) {
  if (molding) {
    let review = {
      img: molding.thumbnail,
      name: molding.name,
      count: molding.count
    };

    let product = products[moldingSKU];
    if(!product){
      let newSKU = moldingSKU
      if(moldingSKU.includes("-Assembled")){
        newSKU.replace("-Assembled", "");
      }else{
        newSKU = newSKU + "-Assembled"
      }
      product = products[newSKU]
    }

    return (
      <div>
        <div style={{ height: downLoad ? null : 120, display: 'flex', justifyContent: "space-between" }}>
          {downLoad ? <S.ReviewQuoteItem style={{ justifyContent: 'unset', width: 300, marginLeft: 15 }}>
            <img style={{ height: 70, width: 70 }} src={review.img} />
            <S.SubCategoryItemLabel style={{ textAlign: 'left', fontSize: 8, color: '#000000' }}>
              {review.name}
            </S.SubCategoryItemLabel>
          </S.ReviewQuoteItem> : <>
            <S.ReviewQuoteItem style={{ justifyContent: 'unset', width: 300, marginLeft: 15 }}>
              <img style={{ height: 70, width: 70 }} src={review.img} />
              <S.SubCategoryItemLabel style={{ textAlign: 'left', fontSize: 14 }}>
                {review.name}
              </S.SubCategoryItemLabel>
            </S.ReviewQuoteItem>
            <S.ReviewQuoteItem style={{ width: 50 }}>
              <S.SubCategoryItemLabel style={{ margin: 'unset', fontSize: 14 }}>{review.count}</S.SubCategoryItemLabel>
            </S.ReviewQuoteItem>
            <S.ReviewQuoteItem style={{ width: 100 }}>
              <S.SubCategoryItemLabel style={{ margin: 'unset', color: TEXT_COLOR_NEUTRAL_2, fontSize: 14 }}>{product && product.regular_price ? `$${product && product.regular_price}` : '$0.00'}</S.SubCategoryItemLabel>
            </S.ReviewQuoteItem>
            <S.ReviewQuoteItem style={{ width: 100 }}>
              <S.DistcountedPriceWrapper colorInfo={product && product.regular_price !== product.discounted_price ? 2 : (product && product.regular_price ? 1 : 0)}>
                <S.SubCategoryItemLabel style={{ margin: 'unset', fontSize: 14, color: product && product.regular_price !== product.discounted_price ? STATUS_POSITIVE_COLOR : (product && product.regular_price ? STATUS_WARNING_COLOR : STATUS_NEGATIVE_COLOR) }}>{product && product.discounted_price ? `$${product && product.discounted_price}` : '$0.00'}</S.SubCategoryItemLabel>
                {product && product.regular_price !== 0 && product.discounted_price === product.regular_price && <StyledTooltip style={{ backgroundColor: TEXT_COLOR_NEUTRAL_0 }} title="Please contact us for Sale price" ><img style={{ height: 12, marginLeft: 7 }} src='\assets\img\svg\lefttoolbar\warning_icon.svg' /></StyledTooltip>}
                {(!!!product || (product && product.regular_price === 0 && product.regular_price === product.discounted_price)) && <StyledTooltip title="This item is not available in the finish you have selected. Please contact us for alternate options in the finish you want."><img style={{ height: 12, marginLeft: 7 }} src='\assets\img\svg\lefttoolbar\error_icon.svg' /></StyledTooltip>}
              </S.DistcountedPriceWrapper>
            </S.ReviewQuoteItem>
          </>
          }
          <S.ReviewQuoteItem style={{ width: downLoad ? 20 : 80, marginRight: 13 }}>
            <S.SubCategoryItemLabel style={{ fontSize: downLoad ? 9 : 14, margin: 'unset', color: downLoad ? '#000000' : (product && product.discounted_price === 0 && STATUS_NEGATIVE_COLOR) }}>{product && product.discounted_price ? `$${product && (product.discounted_price * review.count).toFixed(2)}` : '$0.00'}</S.SubCategoryItemLabel>
          </S.ReviewQuoteItem>
        </div>
        {downLoad ?
          <div style={{ height: '1px', backgroundColor: '#f1ebeb' }} /> : <></>
        }
      </div>
    )
  } else { null }
}
