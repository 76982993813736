import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, fromJS } from 'immutable';
import AttributesEditor from './attributes-editor/attributes-editor';
import { GeometryUtils, MathUtils } from '../../../utils/export';
import {UNIT_INCH, MODE_DRAWING_LINE} from '../../../constants';
import convert from 'convert-units';
import * as PS from '../../toolbar/popup/styles';
import { TEXT_COLOR_NEUTRAL_2, DEFAULT_FONT_FAMILY } from '../../../constants';
import { MdContentCopy, MdContentPaste, MdDelete } from 'react-icons/md';
import { returnReplaceableDeepSearchType } from '../../viewer2d/utils';
import * as S from '../../toolbar/main/styles';

const PRECISION = 2;

export default class ElementEditor extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isMolding: true,
      attributesFormData: this.initAttrData(this.props.element, this.props.layer, this.props.state),
      propertiesFormData: this.initPropData(this.props.element, this.props.layer, this.props.state),
      isResizeActive: false,
    };

    this.updateAttribute = this.updateAttribute.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let scene = this.props.state.get('scene');
    let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
    scene = nextProps.state.get('scene');
    let layer = scene.getIn(['layers', scene.get('selectedLayer')]);
    if (
      this.state.attributesFormData.hashCode() !== nextState.attributesFormData.hashCode() ||
      this.state.propertiesFormData.hashCode() !== nextState.propertiesFormData.hashCode() ||
      this.state.isMolding !== nextState.isMolding ||
      this.props.state.clipboardProperties.hashCode() !== nextProps.state.clipboardProperties.hashCode() ||
      selectedLayer.hashCode() !== layer.hashCode()
    ) return true;

    return false;
  }

  componentWillReceiveProps({ element, layer, state }) {
    let scene = this.props.state.get('scene');
    let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
    if (selectedLayer.hashCode() !== layer.hashCode()){ this.setState({
        attributesFormData: this.initAttrData(element, layer, state),
        propertiesFormData: this.initPropData(element, layer, state)
      });
    }
  }

  initAttrData(element, layer, state) {
    element = typeof element.misc === 'object' ? element.set('misc', new Map(element.misc)) : element;
    // console.log(element.prototype);
    switch (element.prototype) {
      case 'items': {
        let allLines = GeometryUtils.getAllLines(layer);
        let allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
        let x = element.x;
        let y = element.y;
        let rotRad = element.rotation / 180 * Math.PI;
        let w = element.properties.get('width').get('_length');
        let wUnit = element.properties.get('width').get('_unit') || 'cm';
        w = convert(w / 2)
          .from(wUnit)
          .to('cm');
        let h = element.properties.get('depth').get('_length');
        let hUnit = element.properties.get('depth').get('_unit') || 'cm';
        h = convert(h / 2)
          .from(hUnit)
          .to('cm');
        let mx = x - w * Math.cos(rotRad);
        let my = y - w * Math.sin(rotRad);
        let x0 = mx + h * Math.sin(rotRad);
        let y0 = my - h * Math.cos(rotRad);
        let x3 = mx*2 - x0;
        let y3 = my*2 - y0;
        let x1 = x*2 - x3;
        let y1 = y*2 - y3;
        let x2 = x*2 - x0;
        let y2 = y*2 - y0;
        this.setState({isMolding: true});
        if(element.layoutpos === 'Base' && GeometryUtils.isSnappedLine({rect:[{x: x0, y: y0}, {x: x1, y: y1}, {x: x2, y: y2}, {x: x3, y: y3}]}, allLineRects)){
          this.setState({isMolding: false});
        }
        if((element.layoutpos === 'Wall' || element.layoutpos === 'Tall') && !GeometryUtils.isSnappedLine({rect:[{x: x0, y: y0}, {x: x1, y: y1}, {x: x2, y: y2}, {x: x3, y: y3}]}, allLineRects)){
          this.setState({isMolding: false});
        }
        return new Map(element);
      }
      case 'lines': {
        let v_a = layer.vertices.get(element.vertices.get(0));
        let v_b = layer.vertices.get(element.vertices.get(1));
        let distance = GeometryUtils.pointsDistance(v_a.x, v_a.y, v_b.x, v_b.y);
        let _unit = element.misc.get('_unitLength') || UNIT_INCH;
        let _length = convert(distance).from(this.context.catalog.unit).to(_unit);

        if(state.mode == MODE_DRAWING_LINE){
          return new Map({
            vertexOne: v_a,
            vertexTwo: v_b,
            lineLength: new Map({ length: distance, _length, _unit }),
            focus: element.focus
          });
         } else {
          let allLines = layer.lines.toArray();
          let relatedLine = allLines.filter(line => { return line.vertices.toArray().includes(v_b.id) && line.id != element.id })[0];
          let relatedVertexID = relatedLine && (relatedLine.vertices.toArray()[0] == v_b.id ? relatedLine.vertices.toArray()[1] : relatedLine.vertices.toArray()[0]);
          let v_d = relatedVertexID && layer.getIn(["vertices", relatedVertexID]);
          let lineToExtend = relatedVertexID && allLines.filter(line => { return line.vertices.toArray().includes(relatedVertexID) && line.id != relatedLine.id })[0];
          let v_c = lineToExtend && layer.getIn(['vertices', lineToExtend.vertices.toArray()[0] == v_d.id ? lineToExtend.vertices.toArray()[1] : lineToExtend.vertices.toArray()[0]]);
          return new Map({
            vertexOne: v_a,
            vertexTwo: v_b,
            vertexThree: v_c,
            vertexFour: v_d,
            lineToExtend: lineToExtend,
            lineLength: new Map({ length: distance, _length, _unit }),
            focus: element.focus
          });
        }
      }
      case 'holes': {
        let line = layer.lines.get(element.line);
        let { x: x0, y: y0 } = layer.vertices.get(line.vertices.get(0));
        let { x: x1, y: y1 } = layer.vertices.get(line.vertices.get(1));
        let lineLength = GeometryUtils.pointsDistance(x0, y0, x1, y1);
        let startAt = lineLength * element.offset - element.properties.get('width').get('length') / 2;
        let endAt = lineLength - lineLength * element.offset - element.properties.get('width').get('length') / 2;
        let _unitA = element.misc.get('_unitA') || UNIT_INCH;
        let _lengthA = convert(x0 > x1 ? endAt : startAt).from(this.context.catalog.unit).to(_unitA);
        let _unitB = element.misc.get('_unitB') || UNIT_INCH;
        let _lengthB = convert(x0 > x1 ? startAt : endAt).from(this.context.catalog.unit).to(_unitB);

        return new Map({
          offset: element.offset,
          offsetA: new Map({
            length: MathUtils.toFixedFloat(x0 > x1 ? endAt : startAt, PRECISION),
            _length: MathUtils.toFixedFloat(_lengthA, PRECISION),
            _unit: _unitA
          }),
          offsetB: new Map({
            length: MathUtils.toFixedFloat(x0 > x1 ? startAt : endAt, PRECISION),
            _length: MathUtils.toFixedFloat(_lengthB, PRECISION),
            _unit: _unitB
          })
        });
      }
      case 'areas': {
        return new Map({});
      }
      default:
        return null;
    }


  }

  initPropData(element, layer, state) {
    let { catalog } = this.context;
    let mapped = {};
    if(!catalog.elements.hasOwnProperty(element.type) && !(!catalog.elements[element.type] && !!catalog.elements[returnReplaceableDeepSearchType(element.type)])) {
      console.log(`Last Selected ${element.type} Element does not exist in catalog.`);
      return new Map(mapped);
    }

    let catalogElement = catalog.getElement(element.type);
    let isFiller = catalogElement.long_name ? catalogElement.long_name.toUpperCase().includes('FILLER') : false;
    if(isFiller){
      let height = {
        defaultValue : element.properties.get('height').toJS(),
        label : "Height",
        type : "length-measure"
      }
      let depth = {
        defaultValue : element.properties.get('depth').toJS(),
        label : "Depth",
        type : "length-measure"
      }
      catalogElement.properties={
        altitude : catalogElement.properties.altitude,
        width : catalogElement.properties.width,
        height : height,
        depth : depth,
        flip_doorhandle : catalogElement.properties.flip_doorhandle,
        resize_and_scaling : catalogElement.properties.resize_and_scaling,
      };
    }

    for (let name in catalogElement.properties) {
        mapped[name] = new Map({
          currentValue: element.properties.has(name) ? element.properties.get(name) : fromJS(catalogElement.properties[name].defaultValue),
          configs: catalogElement.properties[name]
        });
    }

    return new Map(mapped);
  }

  updateAttribute(attributeName, value) {
    let { attributesFormData } = this.state;
    switch (this.props.element.prototype) {
      case 'items': {
        if(attributeName == 'pos') {
          let xVal = value.x; let yVal = value.y;
          attributesFormData = attributesFormData.set('x', xVal);
          attributesFormData = attributesFormData.set('y', yVal);
        }
        else {
          attributesFormData = attributesFormData.set(attributeName, value);
          this.context.itemsActions.updateRotating(value);
        }
        break;
      }
      case 'lines': {
        switch (attributeName) {
          case 'lineLength':
            {
              let v_0 = attributesFormData.get('vertexOne');
              let v_1 = attributesFormData.get('vertexTwo');
              let v_2 = attributesFormData.get('vertexThree');
              let v_3 = attributesFormData.get('vertexFour');
              let v_b_new = GeometryUtils.extendLine(v_0.x, v_0.y, v_1.x, v_1.y, value.get('length'), PRECISION);
              // Blocked function that as wall changed, opposite wall should changed together.
              // if (v_3 !== undefined){
              //   let delta = {
              //     x : v_b_new.x - v_1.x,
              //     y : v_b_new.y - v_1.y
              //   };
              //   let v_d_new = {
              //     x : v_3.x + delta.x,
              //     y : v_3.y + delta.y
              //   }
              //   console.log('delta',delta);
              //   attributesFormData = attributesFormData.withMutations(attr => {
              //     attr.set('vertexTwo', v_1.merge(v_b_new));
              //     attr.set('vertexFour', v_3.merge(v_d_new));
              //     attr.set('lineLength', value);
              //   });
              // }
              // else{
              //   attributesFormData = attributesFormData.withMutations(attr => {
              //     attr.set('vertexTwo', v_1.merge(v_b_new));
              //     attr.set('lineLength', value);
              //   });
              // }
              attributesFormData = attributesFormData.withMutations(attr => {
                attr.set('vertexTwo', v_1.merge(v_b_new));
                attr.set('lineLength', value);
              });
              break;
            }
          case 'vertexOne':
          case 'vertexTwo':
            {
              attributesFormData = attributesFormData.withMutations(attr => {
                attr.set(attributeName, attr.get(attributeName).merge(value));

                let newDistance = GeometryUtils.verticesDistance(attr.get('vertexOne'), attr.get('vertexTwo'));
                attr.mergeIn(['lineLength'], attr.get('lineLength').merge({
                  'length': newDistance,
                  '_length': convert(newDistance).from(this.context.catalog.unit).to(attr.get('lineLength').get('_unit'))
                }));
              });
              break;
            }
          default:
            {
              attributesFormData = attributesFormData.set(attributeName, value);
              break;
            }
        }
        break;
      }
      case 'holes': {
        switch (attributeName) {
          case 'offsetA':
            {
              let line = this.props.layer.lines.get(this.props.element.line);

              let { x: x0, y: y0 } = this.props.layer.vertices.get(line.vertices.get(0));
              let { x: x1, y: y1 } = this.props.layer.vertices.get(line.vertices.get(1));

              let alpha = GeometryUtils.angleBetweenTwoPoints(x0, y0, x1, y1);
              let lineLength = GeometryUtils.pointsDistance(x0, y0, x1, y1);
              let widthLength = this.props.element.properties.get('width').get('length');
              let halfWidthLength = widthLength / 2;

              let lengthValue = value.get('length');
              lengthValue = Math.max(lengthValue, 0);
              lengthValue = Math.min(lengthValue, lineLength - widthLength);

              let xp = (lengthValue + halfWidthLength) * Math.cos(alpha) + x0;
              let yp = (lengthValue + halfWidthLength) * Math.sin(alpha) + y0;

              let offset = GeometryUtils.pointPositionOnLineSegment(x0, y0, x1, y1, xp, yp);
              if (x0 > x1)   offset = 1- offset;
              let endAt = MathUtils.toFixedFloat(lineLength - (lineLength * offset) - halfWidthLength, PRECISION);
              let offsetUnit = attributesFormData.getIn(['offsetB', '_unit']);

              let offsetB = new Map({
                length: endAt,
                _length: convert(endAt).from(this.context.catalog.unit).to(offsetUnit),
                _unit: offsetUnit
              });

              attributesFormData = attributesFormData.set('offsetB', offsetB).set('offset', offset);

              let offsetAttribute = new Map({
                length: MathUtils.toFixedFloat(lengthValue, PRECISION),
                _unit: value.get('_unit'),
                _length: MathUtils.toFixedFloat(convert(lengthValue).from(this.context.catalog.unit).to(value.get('_unit')), PRECISION)
              });

              attributesFormData = attributesFormData.set(attributeName, offsetAttribute);

              break;
            }
          case 'offsetB':
            {
              let line = this.props.layer.lines.get(this.props.element.line);
              let { x: x0, y: y0 } = this.props.layer.vertices.get(line.vertices.get(0));
              let { x: x1, y: y1 } = this.props.layer.vertices.get(line.vertices.get(1));

              let alpha = GeometryUtils.angleBetweenTwoPoints(x0, y0, x1, y1);
              let lineLength = GeometryUtils.pointsDistance(x0, y0, x1, y1);
              let widthLength = this.props.element.properties.get('width').get('length');
              let halfWidthLength = widthLength / 2;

              let lengthValue = value.get('length');
              lengthValue = Math.max(lengthValue, 0);
              lengthValue = Math.min(lengthValue, lineLength - widthLength);

              let xp = x1 - (lengthValue + halfWidthLength) * Math.cos(alpha);
              let yp = y1 - (lengthValue + halfWidthLength) * Math.sin(alpha);

              let offset = GeometryUtils.pointPositionOnLineSegment(x0, y0, x1, y1, xp, yp);
              if (x0 > x1)   offset = 1- offset;
              let startAt = MathUtils.toFixedFloat((lineLength * offset) - halfWidthLength, PRECISION);
              let offsetUnit = attributesFormData.getIn(['offsetA', '_unit']);

              let offsetA = new Map({
                length: startAt,
                _length: convert(startAt).from(this.context.catalog.unit).to(offsetUnit),
                _unit: offsetUnit
              });

              attributesFormData = attributesFormData.set('offsetA', offsetA).set('offset', offset);

              let offsetAttribute = new Map({
                length: MathUtils.toFixedFloat(lengthValue, PRECISION),
                _unit: value.get('_unit'),
                _length: MathUtils.toFixedFloat(convert(lengthValue).from(this.context.catalog.unit).to(value.get('_unit')), PRECISION)
              });

              attributesFormData = attributesFormData.set(attributeName, offsetAttribute);

              break;
            }
          default:
            {
              attributesFormData = attributesFormData.set(attributeName, value);
              break;
            }
        };
        break;
      }
      default:
        break;
    }

    this.setState({ attributesFormData });
    this.save({ attributesFormData });
  }

  updateProperty(propertyName, value) {
    let { state: { propertiesFormData } } = this;
    propertiesFormData = propertiesFormData.setIn([propertyName, 'currentValue'], value);
    this.setState({ propertiesFormData });
    this.save({ propertiesFormData });
  }

  reset() {
    this.setState({ propertiesFormData: this.initPropData(this.props.element, this.props.layer, this.props.state) });
  }

  save({ propertiesFormData, attributesFormData }) {

    if (propertiesFormData) {
      let properties = propertiesFormData.map(data => {
        return data.get('currentValue');
      });

      this.context.projectActions.setProperties(properties);
    }

    if (attributesFormData) {
      switch (this.props.element.prototype) {
        case 'items': {
          this.context.projectActions.setItemsAttributes(attributesFormData);
          break;
        }
        case 'lines': {
          this.context.projectActions.setLinesAttributes(attributesFormData);
          break;
        }
        case 'holes': {
          this.context.projectActions.setHolesAttributes(attributesFormData);
          break;
        }
      }
    }
  }

  copyProperties(properties) {
    this.context.projectActions.copyProperties(properties);
  }

  pasteProperties() {
    this.context.projectActions.pasteProperties();
  }

  returnResizeFilterList(){
    if(this.state.isResizeActive) return ["altitude", "flip_doorhandle", "resize_and_scaling"]
    return ["width", "height", "depth", "altitude", "flip_doorhandle", "resize_and_scaling"]
  }

  returnMoldings(){
    const element = this.props.element;
    let moldings = [];
    let cabinets = this.props.categoryData.data.cabinets;
    let linears = this.props.layer.linears;
    cabinets.forEach(cabinet=>{
      cabinet.items.forEach(index => {
        if (index.name.toLowerCase().includes('molding')) {
          index.items.forEach(item=>{
            moldings.push(item);
          })
        }
      });
    })
    const selMolding = this.props.layer.molding;
    moldings = moldings.filter(a=>{
      if((element.layoutpos === 'Base' && a.molding_type.includes('Base')) ||
      (element.layoutpos === 'Wall' && a.molding_type.includes('Wall')) ||
      (element.layoutpos === 'Tall' && a.molding_type.includes('Tall'))){
        let flag = false;
        linears.forEach(linear=>{
          if(linear.items.findIndex(item=>item.id === element.id) > -1){
            if(linear.molding.name === a.name){
              flag = true;
            }
          }
        });
        a.selected = flag;
        if(selMolding.findIndex(m=>m.name === a.name) > -1) return false;
        return true;
      }
      return false;
    });
    return moldings;
  }

  setLinear(molding){
    const element = this.props.element;
    const linear = this.props.getLinearOfMolding(molding, element.id);
    if(linear) this.context.itemsActions.setLinear(linear);
  }

  render() {
    let {
      state: { propertiesFormData, attributesFormData, isMolding },
      context: { projectActions, catalog, translator },
      props: { state: appState, element },
    } = this;

    const catalogType= catalog.getElement(element.type).type
    const isCabinet = catalogType=== "cabinet" && !catalog.getElement(element.type).long_name.includes("Filler") && !catalog.getElement(element.type).long_name.includes("Panel")
    const isFiller = catalogType === "cabinet" && catalog.getElement(element.type).long_name.includes("Filler") 
    const isAppliance = catalogType === "appliance"
    const isWindow = catalog.getElement(element.type).info.tag.includes("window")
    const isDoor = catalog.getElement(element.type).info.tag.includes("door")
    const isWall = catalog.getElement(element.type).info.tag.includes("wall")
    const shouldHideResizeAndModel = isCabinet || isWindow || isDoor || isWall
    return (
      <div>
        {propertiesFormData.entrySeq().map(([propertyName, data]) => {
          if (
            (catalog.categoryHasElement('Windows', element.type) ||
              catalog.categoryHasElement('Doors', element.type)) &&
            ['width', 'height'].includes(propertyName)
          ) {
            let currentValue = data.get('currentValue'),
              configs = data.get('configs');
            let { Editor } = catalog.getPropertyType(configs.type);
            return (
              <div style={{position: 'relative'}}>
                <Editor
                  key={propertyName}
                  propertyName={propertyName}
                  value={currentValue}
                  configs={configs}
                  onUpdate={value => this.updateProperty(propertyName, value)}
                  state={appState}
                  sourceElement={element}
                  internalState={this.state}
                />
                {configs.type === 'length-measure' && <span style={{position: 'absolute', fontFamily: DEFAULT_FONT_FAMILY, fontSize: 12, right: 10, bottom: 12, color: TEXT_COLOR_NEUTRAL_2, fontWeight: 600, lineHeight: '17px'}}>{this.props.state.getIn(['scene', 'layers', this.props.state.scene.selectedLayer, 'unit'])}</span>}
              </div>
            );
          }
        })}

        <PS.PositionValue>Positioning</PS.PositionValue>
        <AttributesEditor
          element={element}
          onUpdate={this.updateAttribute}
          propertiesFormData={propertiesFormData}
          catalog={catalog}
          attributeFormData={attributesFormData}
          onConfigUpdate={(propertyName, value) => this.updateProperty(propertyName, value)}
          state={appState}
          internalState={this.state}
          filteredProperties={["width", "height", "depth"]}
        />
        {!shouldHideResizeAndModel &&
          <React.Fragment>
            <PS.PositionValue>Resize Model</PS.PositionValue>
            {propertiesFormData.entrySeq().map(([propertyName, data]) => {
              let currentValue = data.get('currentValue'),
                configs = data.get('configs');
              let { Editor } = catalog.getPropertyType(configs.type);
              if(!this.state.isResizeActive && configs.id ==="resize_and_scaling"){
                this.setState({isResizeActive: currentValue })
              }
              return (configs.type !== "length-measure" && propertyName ==="resize_and_scaling" &&
                <div style={{position: 'relative'}}>
                  <Editor
                    key={propertyName}
                    propertyName={propertyName}
                    value={currentValue}
                    configs={configs}
                    onUpdate={value => {
                      this.updateProperty(propertyName, value)
                      this.setState({...this.state,isResizeActive: value })
                    }}
                    state={appState}
                    sourceElement={element}
                    internalState={this.state}
                  />
                </div>
              )
            })}
            </React.Fragment>
        }
        {!(isWindow || isDoor || isWall) &&
          <AttributesEditor
            element={element}
            onUpdate={this.updateAttribute}
            propertiesFormData={propertiesFormData}
            catalog={catalog}
            attributeFormData={attributesFormData}
            onConfigUpdate={(propertyName, value) => {this.updateProperty(propertyName, value)}}
            state={appState}
            internalState={this.state}
            filteredProperties={this.returnResizeFilterList()}
            shouldOnlyShowEditorOptions={true}
          />
        }
        {/* <div style={attrPorpSeparatorStyle}>
          <div style={headActionStyle}>
            <div title={translator.t('Remove')} style={iconHeadStyle} onClick={e => projectActions.remove()}><MdDelete /></div>
            <div title={translator.t('Copy')} style={iconHeadStyle} onClick={e => this.copyProperties(element.properties)}><MdContentCopy /></div>
            {
              appState.get('clipboardProperties') && appState.get('clipboardProperties').size ?
                <div title={translator.t('Paste')} style={iconHeadStyle} onClick={e => this.pasteProperties()}><MdContentPaste /></div> : null
            }
          </div>
        </div> */}
        {(isCabinet || isDoor) &&
          <React.Fragment>
            <PS.PositionValue>Options</PS.PositionValue>
            {propertiesFormData.entrySeq().map(([propertyName, data]) => {
              if (catalog.categoryHasElement('Windows', element.type)) {
                if (['thickness', 'width', 'height'].includes(propertyName)) {
                  return null;
                }
              }
              if (catalog.categoryHasElement('Doors', element.type)) {
                if (['altitude', 'thickness', 'width', 'height'].includes(propertyName)) {
                  return null;
                }
              }
              if(propertyName ==="resize_and_scaling") return null

              let catalogElement = catalog.getElement(element.type);
              // if ((catalogElement.info.has_single_door === undefined || catalogElement.info.has_single_door == false) && propertyName == 'flip_doorhandle') {
              //   return null;
              // }

              let currentValue = data.get('currentValue'),
                configs = data.get('configs');
              let { Editor } = catalog.getPropertyType(configs.type);
              return ( configs.type !== "length-measure" &&
                <div style={{position: 'relative'}}>
                  <Editor
                    key={propertyName}
                    propertyName={propertyName}
                    value={currentValue}
                    configs={configs}
                    onUpdate={value => this.updateProperty(propertyName, value)}
                    state={appState}
                    sourceElement={element}
                    internalState={this.state}
                  />
                  {configs.type === 'length-measure' && <span style={{position: 'absolute', fontFamily: DEFAULT_FONT_FAMILY, fontSize: 12, right: 10, bottom: 12, color: TEXT_COLOR_NEUTRAL_2, fontWeight: 600, lineHeight: '17px'}}>in</span>}
                </div>
              );
            })}
          </React.Fragment>
        }
        {(isCabinet && isMolding) &&
          <div>
            <PS.PositionValue>Molding</PS.PositionValue>
            {
              this.returnMoldings().map(molding=>{
                return (
                <S.SubCategoryItem id={molding.id} style={{ minWidth: 250 }} onClick={() => {this.setLinear(molding)}} key={molding.id} >
                  <img id={`molding&${molding.id}`} style={{height: 70, width: 70, padding: 5}} src={molding.thumbnail}/>
                  <S.ContentCheckImage id="check" src="/assets/img/svg/wizardstep/check-normal.svg"/>
                  {molding.selected  && <S.ContentCheckImage src="/assets/img/svg/wizardstep/check-active.svg"/>}
                  <S.SubCategoryItemLabel style={{fontWeight: molding.selected && 700}}>{molding.name}</S.SubCategoryItemLabel>
                </S.SubCategoryItem>);
              })
            }
          </div>
        }
      </div>
    );
  }
}

ElementEditor.propTypes = {
  state: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  categoryData: PropTypes.object.isRequired,
  getLinearOfMolding: PropTypes.func.isRequired
};

ElementEditor.contextTypes = {
  itemsActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
