import React from 'react';
import {FinishingCategory} from './category'
import {FinishingProduct} from './product'

import { ToolbarButtonWithPopup, DoubleToolbarButtonWithPopup } from '../'

const FinishingTouchToolbarButton = ({
  dataSource, action, chooseAction, subData, props, productCollapse, isOpen,
  trigger, categoryCollapse, collapseAction, mode, closeAction, applyMaterial }) => {

  let content = <FinishingCategory action={action} dataSource={dataSource} isExpand={!categoryCollapse} props={props} />
  let content1 = <FinishingProduct chooseAction={chooseAction} props={props} dataSource={subData} isExpand={!productCollapse} mode={mode}></FinishingProduct>
  return (
    <DoubleToolbarButtonWithPopup closeAction={closeAction} isOpen={isOpen} mode={mode} order={4} isDouble={subData.length} collapse1={categoryCollapse} collapse2={productCollapse} collapseAction={collapseAction} trigger={trigger} children1={content1} applyMaterial={applyMaterial}>
      {content}
    </DoubleToolbarButtonWithPopup>
  )
}


export default FinishingTouchToolbarButton
