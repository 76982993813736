import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FaPlusCircle as IconAdd} from 'react-icons/fa';
import * as SharedStyle from '../../shared-style';
import {API_SERVER_URL, MODE, MODE_IDLE} from '../../constants';
import browserHistory from '../../@history';
import axios from 'axios';
import SnackBar from '../atoms/Snackbar/index';
const AWS = require('aws-sdk');

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
});


const STYLE_BOX = {
  width: '14em',
  height: '14em',
  padding: '0.625em',
  background: '#f7f7f9',
  border: '1px solid #e1e1e8',
  cursor: 'pointer',
  position: 'relative',
  boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.11), 0 1px 4px 0 rgba(0, 0, 0, 0.11)',
  borderRadius: '2px',
  transition: 'all .15s ease-in-out',
  WebkitTransition: 'all .15s ease-in-out',
  alignSelf: 'center',
  justifySelf: 'center',
};

const STYLE_BOX_HOVER = {
  ...STYLE_BOX,
  background: SharedStyle.SECONDARY_COLOR.main
};

const STYLE_TITLE = {
  width:'100%',
  textAlign:'center',
  display:'block',
  marginBottom:'.5em',
  textTransform: 'capitalize'
};

const STYLE_TITLE_HOVER = {
  ...STYLE_TITLE,
  color:SharedStyle.COLORS.white
};

const STYLE_IMAGE_CONTAINER = {
  width: '100%',
  height: '8em',
  position:'relative',
  overflow:'hidden',
  border: 'solid 1px #e6e6e6',
  padding:0,
  margin:0,
  marginBottom: '5px'
};

const STYLE_IMAGE = {
  position:'absolute',
  background: '#222',
  width: '100%',
  height: '100%',
  backgroundSize: 'contain',
  backgroundPosition:'50% 50%',
  backgroundColor:SharedStyle.COLORS.white,
  backgroundRepeat:'no-repeat',
  transition: 'all .2s ease-in-out'
};

const STYLE_IMAGE_HOVER = {
  ...STYLE_IMAGE,
  transform: 'scale(1.2)'
};

const STYLE_PLUS_HOVER = {
  marginTop:'1.5em',
  color: SharedStyle.SECONDARY_COLOR.main,
  fontSize: '2em',
  opacity: '0.7',
  width: '100%'
};

const STYLE_DESCRIPTION = {
  display: 'block',
  display: '-webkit-box',
  height: '2em',
  margin: '0 auto',
  fontSize: '0.75em',
  fontStyle:'italic',
  lineHeight: '1em',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const STYLE_TAGS = {
  listStyle: 'none',
  margin: '0px',
  padding: '0px',
  fontSize: '11px',
  marginBottom: '3px'
};

const STYLE_TAG = {
  display: 'inline-block',
  background: '#337ab7',
  color: SharedStyle.COLORS.white,
  padding: '1px 4px',
  marginRight: '3px',
  borderRadius: '3px'
};

const STYLE_ASSIGN_TAG = {
  display: 'inline-block',
  background: '#33b768',
  color: SharedStyle.COLORS.white,
  padding: '1px 4px',
  marginRight: '3px',
  borderRadius: '3px'
};

export default class CatalogItem extends Component {

  constructor(props) {
    super(props);
    this.state = {hover: false, snackBarMessage: "",isSnackBarOpen:"" };
  }

  select(element) {
    sessionStorage.setItem('projectTitle', element.title);
    this.context.projectActions.rename (element.title);
    sessionStorage.setItem('projectId', element.id);
    sessionStorage.setItem('email', element.email);
    // console.log(JSON.parse(element.data))
    // if(MODE == "staging") {
    //   this.context.projectActions.loadProject(JSON.parse(element.data));
    // }
    // else {
      const _self = this;
    if (MODE === 'production') {
      s3.getObject({
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: element.data,
      }, function(err, data) {
          if (err !== null) {
              console.log(err);
              this.setState({snackBarMessage: "Something went wrong.", isSnackBarOpen: true})
              return;
          }
          var fileData = data.Body.toString('utf-8');
          const jsonData = JSON.parse(fileData);
          _self.context.projectActions.loadProject(jsonData);
      });
    } else {
      axios.post(`${API_SERVER_URL}/api/project/loadPidData`, {
        pid: element.id
      }).then(response => {
        const jsonData = JSON.parse(response.data.data);
        this.context.projectActions.loadProject(jsonData);
      })
    }
    this.context.projectActions.setMode(MODE_IDLE);
    browserHistory.push({pathname: `/project/${element.role}/${sessionStorage.getItem('jwt_access_token')}/${element.id}`});
  }

  render() {
    let element = this.props.element;
    let hover = this.state.hover;
    return (
      <div
        style={hover ? STYLE_BOX_HOVER : STYLE_BOX}
        onClick={e =>{e.preventDefault(), this.select(element)}}
        onMouseEnter={e => this.setState({hover: true})}
        onMouseLeave={e => this.setState({hover: false})}
      >
        <SnackBar isOpen={this.state.isSnackBarOpen} message={this.state.snackBarMessage} handleClose={()=>this.setState({isSnackBarOpen:false, snackBarMessage: ""})} />
        <b style={ STYLE_TITLE }>{element.title}</b>
        <div style={ STYLE_IMAGE_CONTAINER }>
          <div style={{...( STYLE_IMAGE )}}>
            { hover ? <IconAdd style={STYLE_PLUS_HOVER} /> : null }
          </div>
        </div>
        <ul style={STYLE_TAGS}>
          {
            element.is_assistance_requested === 1 ? <li style={STYLE_ASSIGN_TAG}>Assign</li> : null
          }
          <li style={STYLE_TAG}>{element.saved_at}</li>
        </ul>
        {/* <div style={STYLE_DESCRIPTION}>{element.info.description}</div> */}
      </div>
    );
  }
}

CatalogItem.propTypes = {
  element: PropTypes.object.isRequired,
};

CatalogItem.contextTypes = {
  itemsActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired
};
