import {
  SELECT_HOLE,
  SELECT_TOOL_DRAWING_HOLE,
  SELECT_TOOL_DRAWING_HOLE_3D,
  UPDATE_DRAWING_HOLE,
  UPDATE_DRAWING_HOLE_3D,
  END_DRAWING_HOLE,
  BEGIN_DRAGGING_HOLE,
  BEGIN_DRAGGING_HOLE_3D,
  UPDATE_DRAGGING_HOLE,
  END_DRAGGING_HOLE,
  END_DRAGGING_HOLE_3D,
  END_CREATING_HOLE,
  UPDATE_POPUP_OPEN,
  UPDATE_DRAGGING_HOLE_CHANGED,
  UPDATE_DRAGGING_HOLE_RULER_CHANGED,
  END_DRAWING_HOLE_3D
} from '../constants';

export function endCreatingHole() {
  return {
    type:END_CREATING_HOLE
  };
}

export function updatePopupOpen(value) {
  return {
    type:UPDATE_POPUP_OPEN,
    value
  };
}

export function selectHole(layerID, holeID) {
  return {
    type: SELECT_HOLE,
    layerID,
    holeID
  }
}

export function selectToolDrawingHole(sceneComponentType) {
  return {
    type: SELECT_TOOL_DRAWING_HOLE,
    sceneComponentType
  }
}

export function selectToolDrawingHole3D(sceneComponentType) {
  return {
    type: SELECT_TOOL_DRAWING_HOLE_3D,
    sceneComponentType
  }
}

export function updateDrawingHole(layerID, x, y) {
  return {
    type: UPDATE_DRAWING_HOLE,
    layerID, x, y
  }
}

export function updateDrawingHole3D(layerID, x, y) {
  return {
    type: UPDATE_DRAWING_HOLE_3D,
    layerID, x, y
  }
}

export function endDrawingHole(layerID, x, y) {
  return {
    type: END_DRAWING_HOLE,
    layerID, x, y
  }
}

export function endDrawingHole3D(layerID, x, y) {
  return {
    type: END_DRAWING_HOLE_3D,
    layerID, x, y
  }
}

export function beginDraggingHole(layerID, holeID, x, y) {
  return {
    type: BEGIN_DRAGGING_HOLE,
    layerID, holeID, x, y
  };
}

export function beginDraggingHole3D(layerID, holeID, x, y) {
  return {
    type: BEGIN_DRAGGING_HOLE_3D,
    layerID, holeID, x, y
  };
}

export function updateDraggingHole(x, y) {
  return {
    type: UPDATE_DRAGGING_HOLE,
    x, y
  }
}

export function endDraggingHole(x, y) {
  return {
    type: END_DRAGGING_HOLE,
    x, y
  }
}

export function endDraggingHole3D(x, y) {
  return {
    type: END_DRAGGING_HOLE_3D,
    x, y
  }
}

export function updateDraggingHoleChanged(x, y, layerID, holeID) {
  return {
    type: UPDATE_DRAGGING_HOLE_CHANGED,
    x, y, layerID, holeID
  }
}

export function updateDraggingHoleRulerChanged(width, layerID, holeID) {
  return {
    type: UPDATE_DRAGGING_HOLE_RULER_CHANGED,
    width, layerID, holeID
  }
}
