import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import {
  API_SERVER_URL,
  BG_COLOR_1,
  BG_COLOR_HOVER,
  DEFAULT_FONT_FAMILY,
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_1,
  TEXT_COLOR_NEUTRAL_4
} from '../../constants';
import axios from 'axios';
import history from '../../@history';
import moment from 'moment';

const sortData = ['date', 'name'];

const thumbnail = '/assets/img/project_img.png';

export default class MyProjects extends Component {
  constructor(props, context) {
    super(props);

    this.state = {
      sortType: 'date',
      searchValue: '',
      viewType: 1, //1: detail, 2: tile
      myProjects: []
    };
  }

  getProjects() {
    const user_email = sessionStorage.getItem('email');
    axios
      .post(`${API_SERVER_URL}/api/project/getmyprojects`, {
        email: user_email
      })
      .then(res => {
        if (res.data.success === true) {
          this.setState({ myProjects: res.data.projects });
        }
      });
  }
  componentDidMount() {
    this.getProjects();
  }

  openProject(role, token, pid, urmParam) {
    history.push({ pathname: `/project/${role}/${token}/${pid}?${urmParam}` });
    axios
      .post(`${API_SERVER_URL}/api/project/loadPidProject`, {
        pid: pid
      })
      .then(async response => {
        const { projectElement } = response.data;
        if (projectElement.length === 0) return;
        sessionStorage.setItem('projectTitle', projectElement[0].title);
        this.context.projectActions.rename(projectElement[0].title);
        sessionStorage.setItem('projectId', projectElement[0].id);
        sessionStorage.setItem('email', projectElement[0].email);
        sessionStorage.setItem('firstName', projectElement[0].firstName);
        sessionStorage.setItem('lastName', projectElement[0].lastName);
        sessionStorage.setItem('phone', projectElement[0].phone);
        let jsonData;
        if (projectElement[0].project_data) {
          jsonData = JSON.parse(projectElement[0].project_data);
        } else {
          try {
            jsonData = await axios.post(
              `${constants.API_SERVER_URL}/api/project/loadPidData`,
              { pid: match.params.pid }
            ).data.data;
          } catch (err) {
            this.setState({
              isSnackBarOpen: true,
              snackBarMessage: err
            });
          }
        }
        jsonData.isLoadingCabinet = false;
        this.context.projectActions.loadProject(jsonData);
        this.props.setMyProjectsOpen(false);
      });
  }

  getDateArray(date) {
    let array = date.split(' ');
    let date_array = [];
    array[0].split('-').forEach(element => date_array.push(parseInt(element)));
    array[1].split(':').forEach(element => date_array.push(parseInt(element)));
    return date_array;
  }

  getDiffDate(date) {
    const now_date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const saved_date = moment(date).format('YYYY-MM-DD HH:mm:ss');
    let diffDate = '';
    let now_date_array = this.getDateArray(now_date);
    let saved_date_array = this.getDateArray(saved_date);
    now_date_array.forEach((item, index) => {
      if (item !== saved_date_array[index] && diffDate === '') {
        diffDate = diffDate + (item - saved_date_array[index]);
        switch (index) {
          case 0:
            diffDate = diffDate + ' year';
            break;
          case 1:
            diffDate = diffDate + ' month';
            break;
          case 2:
            diffDate = diffDate + ' day';
            break;
          case 3:
            diffDate = diffDate + ' hour';
            break;
          case 4:
            diffDate = diffDate + ' minute';
            break;
          case 5:
            diffDate = diffDate + ' second';
            break;
          default:
            break;
        }
        if (item - saved_date_array[index] !== 1) {
          diffDate = diffDate + 's';
        }
      }
    });
    return diffDate;
  }

  sort(a, b) {}

  render() {
    const { sortType, searchValue, viewType, myProjects } = this.state;
    const { token, role } = this.props.match.params;

    return (
      <S.DialogWrapper>
        <S.Dialog>
          <div
            style={{ cursor: 'pointer', width: '100%', userSelect: 'none' }}
            onClick={() => this.props.setMyProjectsOpen(false)}
          >
            <img
              style={{ float: 'right', height: 25, transform: 'rotate(45deg)' }}
              src="/assets/img/svg/bottombar/arrow-plus.svg"
            />
          </div>
          <S.DialogTitle>Retrieve a saved projects</S.DialogTitle>
          <S.DialogContent>
            Select from the list below what file do you want to recover.
          </S.DialogContent>
          <S.DialogContentSelection>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <S.DialogContents style={{ fontSize: 13 }}>
                  Sort by:
                </S.DialogContents>
                <S.SelectWrapper>
                  <S.Select
                    id="sort_select"
                    onClick={() => {
                      document.getElementById('arrow').style.transform =
                        document.getElementById('arrow').style.transform ===
                        'rotate(180deg)'
                          ? 'unset'
                          : 'rotate(180deg)';
                      document.getElementById('sort_option').style.display =
                        document.getElementById('sort_option').style.display ===
                        'none'
                          ? 'flex'
                          : 'none';
                      document.getElementById(
                        'sort_select'
                      ).style.borderBottomColor = SECONDARY_PURPLE_COLOR;
                    }}
                  >
                    {sortType.charAt(0).toUpperCase() + sortType.substr(1)}
                  </S.Select>
                  <S.ArrowIcon
                    id="arrow"
                    maskImage="/assets/img/svg/bottombar/arrow-down.svg"
                  />
                  <div
                    id="sort_option"
                    style={{
                      position: 'absolute',
                      display: 'none',
                      flexDirection: 'column',
                      backgroundColor: BG_COLOR_1,
                      top: 30,
                      zIndex: 100,
                      width: 150,
                      borderRadius: 5,
                      backgroundColor: BG_COLOR_1,
                      boxShadow:
                        '0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12)'
                    }}
                  >
                    {sortData.map(item => {
                      return (
                        <S.SelectOption
                          onClick={() => {
                            document.getElementById('arrow').style.transform =
                              'unset';
                            document.getElementById(
                              'sort_option'
                            ).style.display = 'none';
                            document.getElementById(
                              'sort_select'
                            ).style.borderBottomColor = TEXT_COLOR_NEUTRAL_1;
                            this.setState({ sortType: item });
                          }}
                          style={{
                            backgroundColor:
                              sortType === item && TEXT_COLOR_NEUTRAL_4
                          }}
                        >
                          {item.charAt(0).toUpperCase() + item.substr(1)}
                        </S.SelectOption>
                      );
                    })}
                  </div>
                </S.SelectWrapper>
              </div>
              <div style={{ position: 'relative' }}>
                <img
                  src="/assets/img/svg/lefttoolbar/search.svg"
                  style={{
                    position: 'absolute',
                    left: 0,
                    width: 35,
                    height: 35,
                    padding: 10
                  }}
                />
                <S.SearchInput
                  value={searchValue}
                  onChange={e => this.setState({ searchValue: e.target.value })}
                  placeholder="Search file names"
                />
              </div>
              <div style={{ display: 'flex' }}>
                <S.ActionButton
                  onClick={() => this.setState({ viewType: 1 })}
                  style={{ backgroundColor: viewType === 1 && BG_COLOR_HOVER }}
                >
                  <img src="/assets/img/svg/wizardstep/detail_view.svg" />
                </S.ActionButton>
                <S.ActionButton
                  onClick={() => this.setState({ viewType: 2 })}
                  style={{
                    backgroundColor: viewType === 2 && BG_COLOR_HOVER,
                    marginLeft: 10
                  }}
                >
                  <img src="/assets/img/svg/wizardstep/tile_view.svg" />
                </S.ActionButton>
              </div>
            </div>
            <div
              style={{
                height: 400,
                width: '100%',
                scrollY: 'overflow',
                marginTop: 10,
                padding: 5
              }}
            >
              {viewType === 1 ? (
                <S.MyProjectsTable>
                  <S.MyProjectsThead>
                    <S.MyProjectsTh style={{ width: '40%' }}>
                      Name
                    </S.MyProjectsTh>
                    <S.MyProjectsTh style={{ width: '60%' }}>
                      Date
                    </S.MyProjectsTh>
                  </S.MyProjectsThead>
                  {myProjects
                    .sort((a, b) => {
                      let value0, value1;
                      switch(sortType) {
                        case "name":
                          value0 = a.project_title;
                          value1 = b.project_title;
                          break;
                        case "date":
                          value0 = b.create_date;
                          value1 = a.create_date;
                          break;
                        default:
                          break;
                      }
                      return (value0 !== value1 ? (value0 < value1 ? -1 : 1) : 0);
                    })
                    .filter(
                      it =>
                        searchValue === '' ||
                        (it.project_title.search(searchValue) !== -1 ||
                          it.project_title
                            .toLowerCase()
                            .search(searchValue.toLowerCase()) !== -1)
                    )
                    .map(item => {
                      const date = this.getDiffDate(item.create_date);
                      return (
                        <S.MyProjectsThead
                          style={{
                            backgroundColor: BG_COLOR_1,
                            borderBottom: '2px solid #FAFAFF'
                          }}
                        >
                          <S.MyProjectsTh
                            style={{
                              width: '40%',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <img src={thumbnail} style={{ width: 60 }} />
                            <span style={{ marginLeft: 10 }}>
                              {item.project_title.charAt(0).toUpperCase() +
                                item.project_title.substr(1)}
                            </span>
                          </S.MyProjectsTh>
                          <S.MyProjectsTh
                            style={{
                              width: '60%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            {date + ' ago'}
                            <S.ActionButton
                              onClick={() =>
                                this.openProject(
                                  role,
                                  token,
                                  item.id,
                                  item.urmParam
                                )
                              }
                              style={{ width: 'max-content' }}
                            >
                              Open
                            </S.ActionButton>
                          </S.MyProjectsTh>
                        </S.MyProjectsThead>
                      );
                    })}
                </S.MyProjectsTable>
              ) : (
                <div style={{ flexWrap: 'wrap', display: 'flex' }}>
                  {myProjects
                    .sort((a, b) => {
                      let value0, value1;
                      switch(sortType) {
                        case "name":
                          value0 = a.project_title;
                          value1 = b.project_title;
                          break;
                        case "date":
                          value0 = b.create_date;
                          value1 = a.create_date;
                          break;
                        default:
                          break;
                      }
                      return (value0 !== value1 ? (value0 < value1 ? -1 : 1) : 0);
                    })
                    .filter(
                      it =>
                        searchValue === '' ||
                        (it.project_title.search(searchValue) !== -1 ||
                          it.project_title
                            .toLowerCase()
                            .search(searchValue.toLowerCase()) !== -1)
                    )
                    .map(item => {
                      const date = this.getDiffDate(item.create_date);
                      return (
                        <S.TileItem
                          onClick={() =>
                            this.openProject(
                              role,
                              token,
                              item.id,
                              item.urmParam
                            )
                          }
                        >
                          <img src={thumbnail} style={{ width: '100%' }} />
                          <span style={{ marginTop: 10 }}>
                            {item.project_title.charAt(0).toUpperCase() +
                              item.project_title.substr(1)}
                          </span>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: 100,
                              marginTop: 5
                            }}
                          >
                            {date + ' ago'}
                          </span>
                        </S.TileItem>
                      );
                    })}
                </div>
              )}
            </div>
          </S.DialogContentSelection>
        </S.Dialog>
      </S.DialogWrapper>
    );
  }
}

MyProjects.propTypes = {
  state: PropTypes.object.isRequired,
  content: PropTypes.number.isRequired
};

MyProjects.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
