import React from 'react';
import DownloadSummaryTemp from './downloadSummaryTemp';
import * as constants from '../../../../constants';

const ShowCabinetInfo = ({ productDATA, totalPrice }) => {

  return (
    <DownloadSummaryTemp>
      <div style={{}}>
        <h1 style={{ fontWeight: '700' }}>{constants.LIST_PARTS}</h1>
        <div style={{ fontWeight: 'bold' }}>
          {constants.LIST_QUANTITIES}
        </div>
      </div>
      <div style={{ marginTop:'30px'}}>
        <div style={styles.tHeader}>
          <span>{constants.PRODUCT}</span>
          <span>{constants.SUBTOTAL}</span>
        </div>
        <div>
          <div style={{minHeight:'280px'}}>
            {productDATA}
          </div>
          <div style={styles.totalPrice}>
            <span>Total</span>
            <span>${totalPrice}</span>
          </div>
        </div>
      </div>
    </DownloadSummaryTemp>
  );
}

const styles =({
  tHeader:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    height:'25px',
    backgroundColor: "#FAFAFF",
    padding:'0 9px 0 20px',
    color:'#878787'
  },
  totalPrice:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    height:'25px',
    backgroundColor: "#FAFAFF",
    padding:'0 0 0 20px',
    color: '#000',
    fontSize: '10px',
    fontWeight: 'bold',
  },
});

export default ShowCabinetInfo;
