import React from 'react';
import {Input, Form, Row, Col, Select} from 'antd';
import * as S from '../product/styles';
import * as MS from '../styles';

const FormItem = Form.Item;
const {Option} = Select;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const msButtonTitleStyle = {
    top: '39%',
    fontSize: '12'
}

export default class MaterialEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category    : 'handle', 
            metalness   : 0.1, 
            roughness   : 0.1
        };
    }
    
    handleCategoryChange (e) {
        this.setState({category: e.target.value});
    }
    handleMetalnessChange (e) {
        this.setState({metalness: e.target.value});
    }
    handleRoughnessChange (e) {
        this.setState({roughness: e.target.value});
    }
    handleClick(e) {
        const {category, metalness, roughness} = this.state;
        this.props.applyMaterial(category, metalness, roughness);
    }
    render() {
        const {category, metalness, roughness} = this.state;

        return (
            <div style={{position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 100)', border: '1px solid white'}}>
                <S.Control style={{display:'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                    <S.MEditLine>
                        <span>{'Category: '}</span>
                        <select
                            value={category}
                            style={{ width: '65%', marginRight: '3%' }}
                            onChange={(e) => this.handleCategoryChange(e)}
                        >
                            <option value={'handle'}> Handle </option>
                            <option value={'floor_style'}> Floor Style </option>
                            <option value={'backsplash'}> Backsplash </option>
                            <option value={'counter_top'}> Countertop </option>
                            <option value={'appliance'}> Appliance </option>
                        </select>
                    </S.MEditLine>
                    <S.MEditLine>
                        <span>{'Metalness: '}</span>
                        <input
                            type="text"
                            value={metalness}
                            onChange={(e) => this.handleMetalnessChange(e)}
                            style={{ width: '65%', marginRight: '3%' }}
                        />
                    </S.MEditLine>
                    <S.MEditLine>
                        <span>{'Roughness: '}</span>
                        <input
                            type="text"
                            value={roughness}
                            onChange={(e) => this.handleRoughnessChange(e)}
                            style={{ width: '65%', marginRight: '3%' }}
                        />
                    </S.MEditLine>

                    <S.ControlIconMEdit style={{height: '25px', display: 'flex', justifyContent: 'space-around'}} onClick={(e) => this.handleClick(e)}>
                        <img width={'72px'} src={'/assets/img/svg/toolbar/use_button.svg'} />
                        <MS.ButtonTitle style={{...msButtonTitleStyle}}>
                            {'APPLY'}
                        </MS.ButtonTitle>
                    </S.ControlIconMEdit>
                </S.Control>
            </div>
        )
    }
}