import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  KEYBOARD_BUTTON_CODE,
  TEXT_COLOR_NEUTRAL_0,
  SECONDARY_PURPLE_COLOR,
  DEFAULT_FONT_FAMILY
} from '../../constants';
import styled from 'styled-components';

const StyledInput = styled.input`
  display: block;
  padding: 15px 25px 12px 0px;
  width: 120px;
  color: ${TEXT_COLOR_NEUTRAL_0};
  background-color: rgb(255,255,255);
  border: 2px solid;
  text-align: right;
  float: right;
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  font-weight: 600;
  line-height: 17px;
  border-radius: 5px;
  outline: 0;
  :focus{
    border-color: ${SECONDARY_PURPLE_COLOR};
    text-align: right !important;
  }
`

export default class FormNumberInput extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      focus: false,
      valid: true,
      showedValue: props.value,
      focusOn:false,
      flag:true
    };
    this.input = React.createRef(null);
  }

  componentDidMount() {
    if (this.input.current && ["width", "length"].includes(this.props.labelName.toLowerCase())) {
      this.input.current.focus();
      this.input.current.select();
    }
  }

  componentWillReceiveProps( nextProps ) {
    if( this.props.value !== nextProps.value || this.props.focus !== nextProps.focus ) {
      this.setState({ showedValue: nextProps.value, focusOn: nextProps.focus});
    }
  }

  componentDidUpdate(prevProps, preprevState){
    if( this.props.value !== prevProps.value && prevProps.labelName !== "Ceiling Height") {
      this.input.current.focus();
      this.input.current.select();
    }
    return null;
  }

  render() {

    let { value, min, max, precision, onChange, onValid, onInvalid, style, placeholder} = this.props;
    let regexp = new RegExp(`^-?([0-9]+)?\\.?([0-9]{0,${precision}})?$`);

    if (!isNaN(min) && isFinite(min) && this.state.showedValue < min) this.setState({ showedValue: min }); // value = min;
    if (!isNaN(max) && isFinite(max) && this.state.showedValue > max) this.setState({ showedValue: max }); // value = max;

    let currValue = regexp.test(this.state.showedValue) ? this.state.showedValue : parseFloat(this.state.showedValue).toFixed(precision);

    let different = parseFloat(this.props.value).toFixed(precision) !== parseFloat(this.state.showedValue).toFixed(precision);

    let saveFn = (e) => {
      e.stopPropagation();

      if (this.state.valid) {
        let savedValue = (this.state.showedValue !== '' && this.state.showedValue !== '-') ? parseFloat(this.state.showedValue) : 0;

        this.setState({ showedValue: savedValue });
        onChange({ target: { value: savedValue } });
      }
    };

    return (
      <StyledInput
        id='form_number_input'
        autoFocus={this.state.focusOn}
        readOnly={!!this.props.disabled}
        type="text"
        value={currValue}
        style={style}
        onChange={(evt) => {
          this.context.projectActions.copyProperties('ddddd');
          let valid = regexp.test(evt.nativeEvent.target.value);

          if (valid) {
            this.setState({ showedValue: evt.nativeEvent.target.value, flag:false });
            if (onValid) onValid(evt.nativeEvent);
          }
          else {
            if (onInvalid) onInvalid(evt.nativeEvent);
          }
          this.setState({ valid });
        }}
        onFocus = {e => this.setState({ focusOn: true })}
        onBlur = {e => this.setState({ focusOn: false })}
        ref = {this.input}
        onKeyDown={e => {
          var keyCode = e.keyCode || e.which;
          if ((keyCode == KEYBOARD_BUTTON_CODE.ENTER || keyCode == KEYBOARD_BUTTON_CODE.TAB)) {
            saveFn(e);
            this.input.current.blur();
          }
          if(keyCode == KEYBOARD_BUTTON_CODE.ESC){
            this.context.projectActions.rollback();
          }
        }}
        placeholder={placeholder}
      />
    );
  }
}

FormNumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  precision: PropTypes.number,
  placeholder: PropTypes.string,
  labelName: PropTypes.string
};

FormNumberInput.contextTypes = {
  translator: PropTypes.object.isRequired,
  projectActions: PropTypes.object.isRequired,
  linesActions:PropTypes.object.isRequired
};

FormNumberInput.defaultProps = {
  value: 0,
  style: {},
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  precision: 2,
  disabled: false,
  labelName: "default"
};
