import React from 'react';
import * as Three from 'three';
import {loadGLTF, scaleObject} from '../../utils/load-obj';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js';
import { OBJTYPE_MESH } from '../../../../../src/constants';

const hdrUrls = ['px.hdr', 'nx.hdr', 'py.hdr', 'ny.hdr', 'pz.hdr', 'nz.hdr'];
let textureCube = new HDRCubeTextureLoader()
  .setPath('/catalog/envMap/')
  .load(hdrUrls, function () {
  textureCube.magFilter = Three.LinearFilter;
  textureCube.needsUpdate = true;
});

let cached3DWindow = null;

const STYLE_HOLE_BASE = { stroke: '#000', strokeWidth: '3px', fill: '#000' };
const STYLE_HOLE_SELECTED = { stroke: '#0096fd', strokeWidth: '3px', fill: '#0096fd', cursor: 'move' };
const EPSILON = 3;

export default {
  name: 'window-vertical',
  prototype: 'holes',

  info: {
    tag: ['window'],
    title: 'Vertical',
    description: 'Vertical Window',
    image: "/assets/img/svg/window/Vertical.svg",
    url: "/assets/gltf/window_vertical.gltf",
  },

  properties: {
    width: {
      label: 'width',
      type: 'length-measure',
      defaultValue: {
        length: 86.36
      }
    },
    height: {
      label: 'height',
      type: 'length-measure',
      defaultValue: {
        length: 100
      }
    },
    altitude: {
      label: 'Distance from floor',
      type: 'length-measure',
      defaultValue: {
        length: 121.92
      }
    },
    thickness: {
      label: 'thickness',
      type: 'length-measure',
      defaultValue: {
        length: 6
      }
    }
  },

  render2D: function (element, layer, scene) {
    const STYLE_HOLE_BASE = {stroke: 'rgb(73, 73, 73)', strokeWidth: '1px', strokeDasharray: '9,5', fill: 'rgb(73, 73, 73)'};
    const STYLE_HOLE_SELECTED = {stroke: '#0096fd', strokeWidth: '1px', strokeDasharray: '9,5', fill: '#0096fd', cursor: 'move'};
    const STYLE_ARC_BASE = {stroke: 'rgb(73, 73, 73)', strokeWidth: '1px', strokeDasharray: '9,5', fill: 'none'};
    const STYLE_ARC_SELECTED = {stroke: '#0096fd', strokeWidth: '1px', strokeDasharray: '9,5', fill: 'none', cursor: 'move'};
    const STYLE_STR0 = {fill:'rgb(185, 185, 185)',stroke:'#494949',strokeWidth:'1', strokeMiterlimit:'2.61313'};
    const STYLE_STR0_S = {fill:'rgb(185, 185, 185)',stroke:'#0096fd',strokeWidth:'1', strokeMiterlimit:'2.61313'};
    const STYLE_STR1 = {fill:'none', stroke:'#494949', strokeWidth:'1', strokeLinecap:'round', strokeLinejoin:'round', strokeMiterlimit:'2.61313', strokeDasharray:'23.860041 11.930021'};
    const STYLE_FILL2 =  {fill:'#1183B7'};
    const STYLE_FNT0 =  {fill:'white',fontWeight:'normal', fontSize:'12px', fontFamily:'Proxima Nova Rg'}
    //let line = layer.lines.get(hole.line);
    //let epsilon = line.properties.get('thickness') / 2;

    let EPSILON = 3;
    let lineWidth = element.properties.get('thickness').get('length');
    let flip = element.properties.get('flip');
    let holeWidth = element.properties.get('width').get('length');
    let holePath = `M${0} ${ -EPSILON}  L${holeWidth} ${-EPSILON}  L${holeWidth} ${EPSILON}  L${0} ${EPSILON}  z`;
    let arcPath = `M${0},${0}  A${holeWidth},${holeWidth} 0 0,1 ${holeWidth},${holeWidth}`;
    let holeStyle = element.selected ? STYLE_HOLE_SELECTED : STYLE_HOLE_BASE;
    let arcStyle = element.selected ? STYLE_ARC_SELECTED : STYLE_ARC_BASE;
    let rectStyle = element.selected ? STYLE_STR0_S : STYLE_STR0;
    let length = element.properties.get('width').get('length');

      return (
        <g transform={`translate(${-length / 2}, 0)`}>
          {/* <path d={arcPath} style={arcStyle} transform={`translate(${0},${holeWidth}) scale(${1},${-1}) rotate(${0})`}/>
          <line x1={0} y1={holeWidth - EPSILON} x2={0} y2={0 - EPSILON} style={holeStyle} transform={`scale(${-1},${1})`}/> */}
          <rect style={rectStyle} x="0" y={-lineWidth/2} width={holeWidth} height={lineWidth}/>
          {/* <rect style={STYLE_FILL2} x={holeWidth - 16} y="-8.5" width="18" height="17" rx="1.27" ry="1.27"/> */}
          {/* <text x={holeWidth - 15} y="4.5" transform={`scale(1,-1)`} style={STYLE_FNT0}>W4</text> */}
        </g>
      )
  },

  render3D: function (element, layer, scene) {
    let onLoadItem = (object) => {
      let width = element.properties.get('width').get('length');
      let height = element.properties.get('height').get('length');
      let thickness = element.properties.get('thickness').get('length');

      scaleObject(object, [90, 100, 6], [width, height, thickness]);

      if (element.selected) {
        let box = new Three.BoxHelper(object, 0x99c3fb);
        box.material.linewidth = 2;
        box.material.depthTest = false;
        box.renderOrder = 1000;
        object.add(box);
      }

      //let normalMap = require('./texture.png');
      //let t = new Three.TextureLoader().load(normalMap);
      const examplecolor = new Three.Color(0xFFFFFF);
      let mat2 = new Three.MeshStandardMaterial({
        color: examplecolor,
        metalness: 0.1,
        roughness: 0.9
      });
      //mat2.map = t;
      // mat2.envMap = textureCube;

      for (let j = 0; j < object.children.length; j++) {
        if (object.children[j].type === OBJTYPE_MESH) {
          if (object.children[j].name.includes("_glass"))
            object.children[j].scale.z /= 2;
          object.children[j].material = mat2;
          object.children[j].receiveShadow = true;
        }
      }

      return object;
    };

    if(cached3DWindow) {
      return Promise.resolve(onLoadItem(cached3DWindow.clone()));
    }

    return loadGLTF(element.url)
    .then(object => {
      cached3DWindow = object;
      return onLoadItem(cached3DWindow.clone())
    })

    // function createTenda() {

    //   let radialWave = function (u, v) {
    //     let r = 10;
    //     let x = Math.sin(u) * 3 * r;
    //     let z = Math.sin(v / 2) * 2 * r;
    //     let y = (Math.sin(u * 2 * Math.PI) + Math.cos(v * 2 * Math.PI)) * .5;

    //     return new Three.Vector3(x, y, z);
    //   };

    //   //color
    //   let white = new Three.MeshLambertMaterial({ color: 0xeae6ca });

    //   let Tenda = new Three.Object3D();

    //   let mesh = createMesh(new Three.ParametricGeometry(radialWave, 20, 20));
    //   mesh.rotation.x += Math.PI / 2;
    //   mesh.rotation.y += Math.PI / 2;
    //   mesh.position.y += 3.1;
    //   mesh.position.x += .05;
    //   mesh.scale.set(.125, .125, .125);

    //   let mesh2 = mesh.clone();
    //   mesh2.rotation.x += Math.PI;
    //   mesh2.position.set(1.4, 0, 0.06);

    //   Tenda.add(mesh);
    //   Tenda.add(mesh2);

    //   for (let i = -.7; i > -3.4; i -= .45) {
    //     let geometry = new Three.TorusGeometry(.08, .016, 32, 32, 2 * Math.PI);
    //     let torus = new Three.Mesh(geometry, white);

    //     if (i == -1.15)
    //       torus.position.set(i, 3.14, .045);
    //     else if (i == -2.5)
    //       torus.position.set(i, 3.14, -.01);
    //     else
    //       torus.position.set(i, 3.14, .04);
    //     torus.rotation.y += Math.PI / 2;
    //     Tenda.add(torus);
    //   }

    //   let geometryAsta = new Three.CylinderGeometry(0.02, 0.02, 1.25, 32);
    //   let asta = new Three.Mesh(geometryAsta, white);
    //   asta.position.set(-1.1, 3.18, 0.02);
    //   asta.rotation.z += Math.PI / 2;
    //   Tenda.add(asta);

    //   let asta2 = asta.clone();
    //   asta2.position.set(-2.5, 3.18, 0.02);
    //   Tenda.add(asta2);

    //   let geometrySphereUp = new Three.SphereGeometry(0.04, 32, 32);
    //   let sphere = new Three.Mesh(geometrySphereUp, white);
    //   sphere.position.set(-.5, 3.18, 0.02);
    //   sphere.rotation.x += Math.PI / 2;
    //   sphere.scale.set(0.8, 1, 1);
    //   Tenda.add(sphere);

    //   let sphere2 = sphere.clone();
    //   sphere2.position.x += -1.2;
    //   Tenda.add(sphere2);

    //   let sphere3 = sphere.clone();
    //   sphere3.position.x += -1.4;
    //   Tenda.add(sphere3);

    //   let sphere4 = sphere.clone();
    //   sphere4.position.x += -2.6;
    //   Tenda.add(sphere4);

    //   let valueObject = new Three.Box3().setFromObject(Tenda);

    //   let deltaX = Math.abs(valueObject.max.x - valueObject.min.x);
    //   let deltaY = Math.abs(valueObject.max.y - valueObject.min.y);
    //   let deltaZ = Math.abs(valueObject.max.z - valueObject.min.z);

    //   Tenda.position.x += width / 1.48;
    //   Tenda.position.y += -height / 2.1;
    //   Tenda.position.z += thickness / 1024;
    //   Tenda.scale.set(width / deltaX, height / deltaY, thickness / deltaZ);

    //   return Tenda;
    // }

    // function createMesh(geom) {
    //   geom.applyMatrix(new Three.Matrix4().makeTranslation(-25, 0, -25));
    //   let meshMaterial = new Three.MeshLambertMaterial({ color: 0xffffff, opacity: 0.9, transparent: true });
    //   meshMaterial.side = Three.DoubleSide;

    //   let plane = new Three.Mesh(geom, meshMaterial);
    //   return plane;
    // }

  }

};
