import React from 'react';

import * as S from '../../styles';

import Appliance from '../../product/appliance';
import {
  API_SERVER,
  MODE_IDLE,
  MODE_3D_VIEW,
  MODE_3D_FIRST_PERSON,
  MODE_VIEWING_CATALOG,
  MODE_CONFIGURING_PROJECT,
  MODE_IDLE_3D,
  MODE_ROTATING_ITEM_3D,
  MODE_DRAGGING_ITEM_3D,
  MODE_2D_PAN,
  MODE_DRAWING_ITEM,
  MODE_DRAWING_ITEM_3D
} from '../../../../../constants';

export const FinishingProduct = ({
  props,
  mode,
  chooseAction,
  dataSource,
  isExpand,
  applyMaterial
}) => {
  let products = [];

  if (dataSource.length > 0) {
    for (let i = 0; i < dataSource.length; i++) {
      products.push(
        <S.ItemWrapper key={i.toString()} style={{ justifyContent: 'center' }}>
          <Appliance
            name={'finishing'}
            onClick={() => {
              chooseAction(dataSource[i]);
              props.projectActions.unselectAll();
              if (
                dataSource[i].category === 'lighting' ||
                dataSource[i].category === 'furnishing'
              ) {
                props.itemsActions.updatePopupOpen(2);
                if (
                  dataSource[i].category === 'lighting' &&
                  [
                    MODE_IDLE_3D,
                    MODE_DRAWING_ITEM_3D,
                    MODE_DRAGGING_ITEM_3D,
                    MODE_ROTATING_ITEM_3D
                  ].includes(mode)
                )
                  // props.itemsActions.selectToolDrawingItem(dataSource[i].name);
                  props.itemsActions.selectToolDrawingItem3D(
                    dataSource[i].name
                  );
                else {
                  [
                    MODE_IDLE_3D,
                    MODE_DRAWING_ITEM_3D,
                    MODE_DRAGGING_ITEM_3D,
                    MODE_ROTATING_ITEM_3D
                  ].includes(mode)
                    ? props.itemsActions.selectToolDrawingItem3D(
                        dataSource[i].name
                      )
                    : props.itemsActions.selectToolDrawingItem(
                        dataSource[i].name
                      );
                }
                props.projectActions.pushLastSelectedCatalogElementToHistory(
                  props.catalog.elements[dataSource[i].name]
                );
                if (document.getElementsByClassName('popup-content').length > 0)
                  document.getElementsByClassName(
                    'popup-content'
                  )[0].style.display =
                    'none';
              }
            }}
            selected={dataSource[i].selected ? dataSource[i].selected : null}
            url={
              dataSource[i].category != undefined &&
              dataSource[i].category !== 'modelling' &&
              dataSource[i].category !== 'floor_style' &&
              dataSource[i].category !== 'lighting' &&
              dataSource[i].category !== 'counter_top' &&
              dataSource[i].category !== 'backsplash' &&
              dataSource[i].category !== 'furnishing'
                ? dataSource[i].color
                : dataSource[i].thumbnail
            }
            title={dataSource[i].name}
            category={
              dataSource[i].category !== 'floor_style' ||
              dataSource[i].category !== 'backsplash' ||
              dataSource[i].category !== 'counter_top'
                ? ''
                : dataSource[i].category
            }
          />
        </S.ItemWrapper>
      );
    }
  }
  let miHeight = 0;
  let nonHeight = 495 - miHeight + 'px';
  let height = window.innerHeight * 0.6 - miHeight + 'px';
  return (
    <S.ProductsContentWrapper
      style={{ width: 220, height: isExpand ? height : nonHeight }}
    >
      {products}
    </S.ProductsContentWrapper>
  );
};
