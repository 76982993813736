import styled from 'styled-components';

export const Main = styled.div`
  z-index: 6;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  position: relative;
  cursor: pointer;
  background: #d3e7e5;
  box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
`
export const rightIcon = styled.div`
  img{
    box-shadow: 3px 1px 3px 0px rgba(0,0,0,0.2), -1px -1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    border-radius:8%;
  }
`
export const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  display: none;
`
export const Title = styled.span`
  position: absolute;
  top: 51%;
  left: 0px;
  width: 100%;
  color: #FFF;
  text-transform: uppercase;
  transform: translate(0%, -50%);
  font-size: 11px;
`
export const rMain = styled.div`
  width: 40px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  position: relative;
  cursor: pointer;
`
export const rOverlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  display: none;
`
export const rTitle = styled.span`
  position: absolute;
  top: 45%;
  left: 1px;
  width: 100%;
  color: #FFF;
  text-transform: uppercase;
  transform: translate(0%, -50%);
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
`

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  margin-left: 20px;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  :hover{
    box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12);
    border-radius: 18px;
  }
`

export const ToggleIcon = styled.div`
  ${'' /* transition-duration: .3s; */}
  position: absolute;
  top: 0px;
  img {
    font-size: 1.4rem;
    box-sizing: border-box;
    border-radius: 50%;
  }`

export const ControlTitle = styled.span`
  margin:10px;
  font-size: 12px;
`