import React from 'react';
import Scene3DDownload from './scene3DDownload';

export default function State3DDownload({state,catalog,width,height}) {

  let { scene } = state;

  return (
    <g>
      <Scene3DDownload scene={scene} catalog={catalog} width={width} height={height} mode={state.get('mode')}/>
    </g>
  )
}
