import KitchenConfiguratorAreasReducer from './areas-reducer';
import KitchenConfiguratorHolesReducer from './holes-reducer';
import KitchenConfiguratorItemsReducer from './items-reducer';
import KitchenConfiguratorLinesReducer from './lines-reducer';
import KitchenConfiguratorGroupsReducer from './groups-reducer';
import KitchenConfiguratorProjectReducer from './project-reducer';
import KitchenConfiguratorSceneReducer from './scene-reducer';
import KitchenConfiguratorVerticesReducer from './vertices-reducer';
import KitchenConfiguratorViewer2dReducer from './viewer2d-reducer';
import KitchenConfiguratorViewer3dReducer from './viewer3d-reducer';
import KitchenConfiguratorUserReducer from './user-reducer';

export {
  KitchenConfiguratorAreasReducer,
  KitchenConfiguratorHolesReducer,
  KitchenConfiguratorItemsReducer,
  KitchenConfiguratorLinesReducer,
  KitchenConfiguratorGroupsReducer,
  KitchenConfiguratorProjectReducer,
  KitchenConfiguratorSceneReducer,
  KitchenConfiguratorVerticesReducer,
  KitchenConfiguratorViewer2dReducer,
  KitchenConfiguratorViewer3dReducer,
  KitchenConfiguratorUserReducer
};

export default {
  KitchenConfiguratorAreasReducer,
  KitchenConfiguratorHolesReducer,
  KitchenConfiguratorItemsReducer,
  KitchenConfiguratorLinesReducer,
  KitchenConfiguratorGroupsReducer,
  KitchenConfiguratorProjectReducer,
  KitchenConfiguratorSceneReducer,
  KitchenConfiguratorVerticesReducer,
  KitchenConfiguratorViewer2dReducer,
  KitchenConfiguratorViewer3dReducer,
  KitchenConfiguratorUserReducer
}
