
import ToolbarComponents from './toolbar/export';
import Content from './content';
import SidebarComponents from './sidebar/export';
import FooterBarComponents from './footerbar/export';
import Viewer2DComponents from './viewer2d/export';
import StyleComponents from './style/export';
import HeaderComponents from './header/export';
import WizardStepComponents from './wizardstep/export';
import MenubarComponents from './menubar/export';
import SignComponents from './sign/export';
import MyProjectsComonents from './myprojects/export';

export {
  ToolbarComponents,
  Content,
  SidebarComponents,
  FooterBarComponents,
  Viewer2DComponents,
  StyleComponents,
  HeaderComponents,
  WizardStepComponents,
  MenubarComponents,
  SignComponents,
  MyProjectsComonents,
};

export default {
  ToolbarComponents,
  Content,
  SidebarComponents,
  FooterBarComponents,
  WizardStepComponents,
  Viewer2DComponents,
  StyleComponents,
  HeaderComponents,
  MenubarComponents,
  SignComponents,
  MyProjectsComonents,
};
