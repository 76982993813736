import React, { useState } from 'react';
import { Popup as BasePopup } from "reactjs-popup"
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { FaAngleDoubleLeft, FaTimes, FaTrash, FaCopy, FaExpand } from 'react-icons/fa';
import {
  // API_SERVER,
  ARRAY_3D_MODES,
} from '../../../constants';

import MaterialEdit from './finishingtouch/material-edit'
import * as S from './styles';

const Popup = ({mode, trigger, children, collapse, collapseAction, order, isOpened, closeAction }) => {
  let title = trigger.props.title;
  return (
    <BasePopup
      onClose={closeAction}
      open={isOpened}
      closeOnDocumentClick
      trigger={<div>{trigger}</div>}
      position="right top"
      offsetX={!ARRAY_3D_MODES.includes(mode) ? 99 : 10}
      offsetY={-90.15 * order}
      on={["click"]}
      mouseLeaveDelay={100}
      arrow={false}
    >
      {closePopup =>
        (
          <S.Wrapper style={{background: '#3e3e3f'}}>
            <S.TitleBar>
              <S.Title>{title}</S.Title>
              <S.TitleBarIconButton onClick={closePopup}>
                <S.IconButton><FaTimes /></S.IconButton>
              </S.TitleBarIconButton>
            </S.TitleBar>
            <S.ContentWrapper>
               {children}
            </S.ContentWrapper>
          </S.Wrapper>
          )
      }
    </BasePopup>
  )
}

const DoublePopup = ({mode, isDouble, trigger, children, children1, collapse1, collapse2, order, isExpand, expandAction, isOpen, closeAction,applyMaterial}) => {
  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) { return a.toUpperCase(); });
  };
  let pathTitle1 = trigger.props.title1;
  let pathTitle2 = trigger.props.title2;
  let title1 = trigger.props.title;
  let title2 = trigger.props.title;
  if (pathTitle1.length > 0 && collapse1) {
    title1 = "";
    for (let i = 0; i < pathTitle1.length; i++) {
      pathTitle1[i] = capitalize(pathTitle1[i]);
      title1 += pathTitle1[i];
      title1 += "/";
    }
  }
  if (pathTitle2.length > 0 && collapse2) {
    title2 = "";
    for (let i = 0; i < pathTitle2.length; i++) {
      title2 += pathTitle2[i];
      title2 += "/";
    }
  }
  
  return (
    <BasePopup
      closeOnDocumentClick
      trigger={<div>{trigger}</div>}
      position="right top"
      offsetX={!ARRAY_3D_MODES.includes(mode) ? 99 : 10}
      offsetY={-90.15 * order}
      on={["click"]}
      mouseLeaveDelay={100}
      arrow={false}
      contentStyle={{top: "110px!important"}}
      open = {isOpen}
      onClose = {closeAction}
    >
      {closePopup => (
        <S.Wrapper>
          <S.TitlesRow>
            <S.TitleBar>
                <S.Title>{title1}</S.Title>
                <S.TitleBarIconButton className={"popup-exit"} onClick={closePopup}>
                  <S.IconButton><FaTimes /></S.IconButton>
                </S.TitleBarIconButton>
                { isExpand > -1 &&
                <S.TitleBarIconButton>
                  <S.IconButton onClick={() => expandAction()}>
                    {
                      title1 === "Select Door Style"
                        ?
                          isExpand === 1
                            ?
                              <ZoomOutOutlined style = {{ fontSize: "14px" }} />
                            :
                              <ZoomInOutlined style = {{ fontSize: "14px" }} />
                        :
                        <FaAngleDoubleLeft style={{transform: isExpand === 1 ? "rotate(0deg)" : "rotate(180deg)"}} />
                    }
                  </S.IconButton>
                </S.TitleBarIconButton>}
            </S.TitleBar>
            <div style={{display:'grid', gridAutoFlow: 'column'}}>
              <section>
                <div style={{backgroundColor: '#3e3e3f'}}>
                  {collapse2 ? children : ""}
                  <S.ClearFix />
                </div>
              </section>
              <section style={{display: isDouble ? 'block' : 'none'}}>
                <div style={{ backgroundColor: '#3e3e3f', display: !collapse2 ? "none" : "block" }}>
                  {collapse2 ? children1 : ""}
                  <S.ClearFix />
                  {(title1.includes('Finishing Touch') || title1.includes('Appliance') ) && process.env.MODE == 'staging' 
                    ? <MaterialEdit applyMaterial={applyMaterial} /> : null}
                </div>
              </section>
            </div>
          </S.TitlesRow>
        </S.Wrapper>
      )}
    </BasePopup>
  )
}

export const ToolbarButtonWithPopup = ({mode, order, trigger, children, props, collapseFlag, collapseAction, isOpened, closeAction }) => {
  return (
  <Popup closeAction={closeAction} mode={mode} trigger={trigger} props={props} collapse1={collapseFlag} collapseAction={collapseAction} order={order} isOpened={isOpened}>
    {children}
  </Popup>
  );
}

export const DoubleToolbarButtonWithPopup = ({mode, isOpen, isExpand, expandAction, trigger, children, children1, collapse1, collapse2, collapseAction, isDouble, order, closeAction,applyMaterial }) => {
  return (
    <DoublePopup closeAction={closeAction} mode={mode} isOpen={isOpen} isExpand={isExpand} expandAction={expandAction} isDouble={isDouble} trigger={trigger} children1={children1} collapse1={collapse1} collapse2={collapse2}  collapseAction={collapseAction} order={order}
      applyMaterial={applyMaterial}
    >
      {children}
    </DoublePopup>
  )
}
