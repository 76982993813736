import React from 'react';
import * as S from '../../../toolbar/popup/floorplan/choose-floor/styles';
import * as PS from '../../../toolbar/popup/styles';
import { FaTimes } from 'react-icons/fa';

class ConfirmPopup extends React.Component {
  constructor(props) {
    super(props);
  }
  
  _submit () {
    this.props.ok();
    this.closePanel();
  }
  closePanel () {
    this.props.handleConfirmLoad(false);
  }
  render() {
    let { visible } = this.props;
    return (
      <div style={{ width: '100%', height: '100%', display: visible ? 'block' : 'none', userSelect: 'none' }}>
        <S.Div>
          <div style={{ backgroundColor: '#1183B7', height: '30px', paddingRight: '6px' }}>
            <div style={{ float: 'right', marginTop: '4px' }} onClick={() => this.closePanel()}>
              <PS.IconButton><FaTimes /></PS.IconButton>
            </div>
          </div>
          <div style={{ fontSize: 14, marginTop: 20, marginLeft: 40 }}>
            The item will be placed outside the floor plan<br/>
            Are you sure?
          </div>
          <div style={{ fontSize: 12, display: 'flex', flexDirection: 'column', position: 'relative' }}>
          </div>
          <div style={{ display: 'flex', position: 'relative'}}>
            <div style={{ width: '70px', marginLeft: '90px', marginTop: '15px', cursor: 'pointer' }} onClick={() => { this._submit()}}>
              <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/cancel_button.svg'} />
              <S.ButtonTitle1>
                {'Yes'}
              </S.ButtonTitle1>
            </div>
            <div style={{ width: '70px', marginLeft: '40px', marginTop: '15px', cursor: 'pointer' }} onClick={() => this.closePanel()}>
              <img width={'80px'} style={{ float: 'right' }} src={'/assets/img/svg/toolbar/use_button.svg'} />
              <S.ButtonTitle2>
                {'No'}
              </S.ButtonTitle2>
            </div>
          </div>
        </S.Div>
      </div>
    )
  }
}


export default ConfirmPopup