import styled from 'styled-components';

import * as SharedStyle from '../../../../shared-style';

// export const Wrapper = styled.div`
//   width: 66px;
//   color: #FFF;
//   margin: 10px 5px 0px 5px;
//   :hover{
//     color:${SharedStyle.TOOLBAR_COLOR.active};
//   }
// `

// export const Image = styled.div`
//   text-align: center;
//   div{
//     width: 70px;
//     height: 90px;
//   }
//   img {
//     width: 65px;
//     height: 80px;
//     margin-top: 10px;
//     padding: 8px;
//     cursor: pointer;
//     transition: 0.3s;
//   }
//   :hover{
//     img {
//       margin-top: 0;
//       width:70px;
//       height: 90px;
//     }
//   }
// `

export const Wrapper = styled.div`
  margin-top: 10px;
  width: 80px;
  color: #FFF;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  cursor: pointer;
  div:first-child{
    height: 60px;
    display: flex;
    justify-content: space-around;
    img{
      height: 90%;
      margin-top: 5%;
      width: auto;
      transition: 0.3s;
    }
  }
  :hover{
    color:${SharedStyle.TOOLBAR_COLOR.active};
    div img{
        margin-top: 0px;
        height: 100%;
    }
}
`

export const CabinetWrapper = styled.div`
  width: 85px;
  color: #FFF;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  cursor: pointer;
  div{
    height: 60px;
    display: flex;
    justify-content: space-around;
    img{
      height: 90%;
      margin-top: 5%;
      width: auto;
      transition: 0.3s;
    }
  }
  :hover{
    color:${SharedStyle.TOOLBAR_COLOR.active};
    div img{
        margin-top: 0px;
        height: 100%;
    }
}
`

export const PrimayDoorStyleLineWrapper = styled.div`
  color: #FFF;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${'' /* :hover{
    span {
      color:${SharedStyle.SECONDARY_COLOR.main};
    }
  } */}
`

// export const LineWrapper = styled.div`
//   color: #FFF;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// `


export const LineWrapper = styled.div`
  color: #FFF;
  display: flex;
  align-items: center;
  ${'' /* height: 160px; */}
  cursor: pointer;
  ${'' /* span {
    font-size:10px;
  } */}
`


export const LineImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    img {

      transition: 0.3s;
    }
`

export const Title = styled.p`
  text-align: center;
`
export const Category = styled.div`
  text-align: center;
  margin-top: 10px;
  width: 66px;
  height: 70px;
  background:white;
  margin: 10px 5px 0px 5px;
  display:flex;
  align-items:center;
  justify-content:center;
  :hover{
    color:${SharedStyle.TOOLBAR_COLOR.active};
  }
`
export const CategoryTitle = styled.div`
  text-align: center;
  word-break: break-word;
  min-width: 40px;
`
export const ContentWraper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const SubCollapse = styled.ul`
  list-style: none;
  display: none;
  transition-duration: 3s;
  padding: 0;
  margin: 8px 0;
`
export const NavItem = styled.li`
  background: 0 0;
  margin: 0;
  color: #fff;
  margin-top: 1px!important;
  padding-left: 5.6rem;
`

export const Control = styled.div`
  height: fit-content;
  justify-content: center;
  display: flex;
`
export const UnControl = styled.div`
  height: fit-content;
  justify-content: center;
  display: flex;
  cursor: no-drop;
`
export const ControlIcon = styled.div`
  ${'' /* padding: 10px 5px; */}
  justify-self: center;
  cursor:pointer;
  position: relative;
  :hover{
    img {
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
    }
    background-color: ${SharedStyle.TOOLBAR_COLOR.focus};
  }
  :active {
    img {
      box-shadow: none !important;
    }
  }
`
export const ControlIconMEdit = styled.div`
  ${'' /* padding: 10px 5px; */}
  cursor:pointer;
  position: relative;
  :hover{
    img {
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.39), 0px 1px 18px 0px rgba(0,0,0,0.12);
    }
    background-color: white;
  }
  :active {
    img {
      box-shadow: none !important;
    }
  }
`
export const MEditLine = styled.div`
  padding: 5px 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const FloorPlanContentWraper = styled.div`
  ${'' /* padding-bottom: 1px; */}
  ${'' /* margin-top: 1px; */}
  user-select: none;
  min-width: 185px;
  max-width:185px;
`

export const FloorPlanWraper = styled.div`
  ${'' /* border-bottom: 2px solid #2f2f2fb3; */}
  padding-bottom: 1px;
  margin-top: 1px;
  user-select: none;
  min-width: 185px;
  max-width:185px;
`

export const PlanWraper = styled.div`
  border-bottom: 2px solid #2f2f2f;
  ${'' /* padding-bottom: 1px; */}
  ${'' /* margin-top: 1px; */}
  user-select: none;
  min-width: 185px;
  max-width:185px;
  :hover{
    span {
      color:${SharedStyle.SECONDARY_COLOR.main};
    }
`
