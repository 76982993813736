import React from 'react';
import PropTypes from 'prop-types';
import convert from 'convert-units';

const ARROW_STYLE = {
  stroke: "#1183B7",
  strokeWidth: "2px",
  strokeLinecap: 'round'
};

const STYLE = {
  stroke: "black",
  strokeWidth: "1.1px"
};

const STYLE_TEXT = {
  textAnchor: "middle",
  fontSize: "10px",
  pointerEvents: "none",

  //http://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting-using-css
  WebkitTouchCallout: "none", /* iOS Safari */
  WebkitUserSelect: "none",   /* Chrome/Safari/Opera */
  MozUserSelect: "none",      /* Firefox */
  MsUserSelect: "none",       /* Internet Explorer/Edge */
  userSelect: "none"
};


export default function RulerDist({ layer, length, unit, rulerUnit, transform, angle, rotation}) {
  let _unit = "in";
  // let _length = convert(length).from(unit).to(rulerUnit);
  let distanceText = `${convert(length).from('cm').to(rulerUnit).toFixed(0)}`;
  let textLength = (distanceText.length + layer.unit.length) * 18;
  let textangle = angle + 90;
  let textRotation = 1;
  let ay = 2;
  let ep = Math.sin(textangle * Math.PI / 180);
  let es = Math.sin(angle * Math.PI / 180);

  if(rotation === 180 && ep >= 0) {
    textRotation = 180;
    ay = 5;
  }
  else if(rotation === 180 && ep <= 0) {
    textRotation = 0;
    ay = -3;
  }
  else if(rotation === 0 && ep >= 0) {
    textRotation = 0;
    ay = -3;
  }
  else if(rotation === 0 && ep <= 0) {
    textRotation = 180;
    ay = 5;
  }
  if(rotation === 90 && es >= 0) {
    textRotation = 180;
    ay = 5;
  }
  else if(rotation === 90 && es <= 0) {
    textRotation = 0;
    ay = -3;
  }
  else if(rotation === -90 && es >= 0) {
    textRotation = 0;
    ay = -3;
  }
  else if(rotation === -90 && es <= 0) {
    textRotation = 180;
    ay = 5;
  }
  if(Math.abs((length-textLength)) / 2 <= 16 ){
    let dy = (textRotation == 0) ? 15 : -15;
    return (
      <g transform={transform}>
        <g transform={`translate(0, ${dy})`}>
        <text x="0" y="0" transform={`translate(${length / 2},${ay}) scale(1, -1) rotate(${textRotation})`} style={STYLE_TEXT}>{Math.round(convert(distanceText).from("in").to(layer.unit) * 100) / 100}{layer.unit}</text>
        </g>
        <circle cx="1" cy="0" fill="white" r="1.1" stroke="black" strokeWidth="0.6" />
        <line x1="2" y1="0" x2={(length - textLength) / 2 < 0 ? 0 : ( length ) } y2="0" style={STYLE} />
        <line x1={(length + textLength) / 2 < 0 ? 0 : (length + textLength) / 2 < length ? (length + textLength) / 2 : length} y1="0" x2={length} y2="0" style={STYLE} />
        <circle cx={length - 1} cy="0" fill="white" r="1.1" stroke="black" strokeWidth="0.6" />
      </g>
  );
  }
  else{
    return (
      <g transform={transform}>
        <g>
        <text x="0" y="0" transform={`translate(${length / 2},${ay}) scale(1, -1) rotate(${textRotation})`} style={STYLE_TEXT}>{Math.round(convert(distanceText).from("in").to(layer.unit) * 100) / 100}{layer.unit}</text>
        </g>
        <circle cx="1" cy="0" fill="white" r="1.1" stroke="black" strokeWidth="0.6" />
        <line x1="2" y1="0" x2={(length - textLength) / 2 < 0 ? 0 : (length - textLength) / 2} y2="0" style={STYLE} />
        <line x1={(length + textLength) / 2 < 0 ? 0 : (length + textLength) / 2 < length ? (length + textLength) / 2 : length} y1="0" x2={length} y2="0" style={STYLE} />
        <circle cx={length - 1} cy="0" fill="white" r="1.1" stroke="black" strokeWidth="0.6" />
      </g>
  );
  }
}

RulerDist.propTypes = {
  length: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  transform: PropTypes.string.isRequired
};
