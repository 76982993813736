import React, { useState } from 'react';

import { DoubleToolbarButtonWithPopup } from '../';

import { ApplianceChooseProduct } from './choose-appliance/';
import { ApplianceCategory } from './appliance-category';

import { STEP_CABINET_CHOOSE_PRODUCT } from '../../../../constants';

const ApplianceToolbarButton = ({
  trigger,
  action,
  dataSource,
  subData,
  bFlag,
  props,
  dData,
  isExpand,
  expandAction,
  closeAction,
  cabinetCollapse,
  doorCollapse,
  doorExpand,
  collapseAction,
  searchCabinet,
  search,
  mode,
  isOpen
}) => {
  const [step, setStep] = useState(STEP_CABINET_CHOOSE_PRODUCT);
  let content = <div />;
  let content1 = <div />;
  content1 = (
    <ApplianceChooseProduct
      action={action}
      pData={subData}
      dData={dData}
      bFlag={0}
      doorExpand={doorExpand}
      props={props}
      setStep={setStep}
      search={searchCabinet}
      onSearch={search}
      mode={mode}
      isExpand={isExpand}
      closeAction={closeAction}
    ></ApplianceChooseProduct>
  );
  content = (
    <ApplianceCategory
      action={action}
      pData={dataSource}
      bFlag={bFlag}
      doorExpand={doorExpand}
      props={props}
      setStep={setStep}
      search={searchCabinet}
      onSearch={search}
      mode={mode}
    ></ApplianceCategory>
  );
  return (
    <DoubleToolbarButtonWithPopup
      closeAction={closeAction}
      isOpen={isOpen}
      mode={mode}
      isExpand={isExpand}
      expandAction={expandAction}
      style={{ minWidth: step >= 1 && isExpand == 1 ? '800px' : '600px' }}
      order={3}
      isDouble={subData.length}
      collapse1={cabinetCollapse}
      collapse2={doorCollapse}
      collapseAction={collapseAction}
      trigger={trigger}
      children1={content1}
    >
      {content}
    </DoubleToolbarButtonWithPopup>
  );
};

export default ApplianceToolbarButton;
