import * as Three from 'three';
import { List } from 'immutable';
import { COLORS } from '../../../shared-style';

export default function (width, height, grid, font) {
  let step = grid.properties.get('step');
  let colors = grid.properties.has('color') ? new List([grid.properties.get('color')]) : grid.properties.get('colors');

  let streak = new Three.Object3D();
  streak.name = 'streak';
  let counter = 0;

  for (let i = 0; i <= height; i += step) {
    for (let j = 0; j <= width; j += step) {

      let geometry = new Three.BufferGeometry();
      geometry.setAttribute('position', new Three.BufferAttribute(new Float32Array([0, 0, -i, width, 0, -i]), 3));
      let color = colors.get(counter % colors.size);
      let material = new Three.LineBasicMaterial({ color });

      const circlegeometry = new Three.CircleGeometry(3, 32);  // 3D dot's radius
      const circle = new Three.Mesh(circlegeometry, material);
      circle.rotation.x -= Math.PI / 2;
      circle.position.set(j, -0.5, -i);
      streak.add(circle);
      counter++;
    }
  }
  return streak;
}
