import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import {
  API_SERVER_URL,
  SECONDARY_PURPLE_COLOR,
  STATUS_NEGATIVE_COLOR,
  STATUS_POSITIVE_COLOR,
  TEXT_COLOR_NEUTRAL_2
} from '../../../constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import SnackBar from '../../atoms/Snackbar';
import axios from 'axios';
import { emailValidation } from '../../../utils/email-validator';
import jwtService from '../../login/jwtService';
import history from '../../../@history';

const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export default class Sign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 1, //1: login, 2: register
      email: '',
      emailfocusOn: false,
      password: '',
      passwordfocusOn: false,
      remember: false,
      firstName: '',
      firstNamefocusOn: false,
      lastName: '',
      lastNamefocusOn: false,
      phoneNumber: '',
      phoneNumberfocusOn: false,
      confirm: '',
      confirmfocusOn: false,
      isSnackBarOpen: false,
      snackBarMessage: '',
      snackBarColor: ''
    };
  }

  closePanel() {
    this.props.setSignOpen(false);
  }

  login(data) {
    axios
      .post(`${API_SERVER_URL}/api/user/customer_login`, data)
      .then(res => {
        if(res.data.success === true){
          // const {email, firstName, lastName, phone, role} = res.data.userData;
          sessionStorage.setItem('email', res.data.userData.email);
          sessionStorage.setItem('firstName', res.data.userData.firstName);
          sessionStorage.setItem('lastName', res.data.userData.lastName);
          sessionStorage.setItem('phone', res.data.userData.phone);
          history.push({pathname: `/project/${res.data.userData.role}/${res.data.token}/null?null`});
          this.setState({
            isSnackBarOpen: true,
            snackBarMessage: `${this.state.type === 1 ? "Login" : "Register"} Successed!`,
            snackBarColor: STATUS_POSITIVE_COLOR
          });
          this.props.setSignOpen(false);
          if(this.props.myProjectsToLogin){
            this.props.setMyProjectsToLogin(false);
            this.props.setMyProjectsOpen(true);
          }
        } else {
          this.setState({
            isSnackBarOpen: true,
            snackBarMessage: res.data.error,
            snackBarColor: STATUS_NEGATIVE_COLOR,
          });
          return;
        }
      });
  }

  _submit() {
    const {
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      confirm,
      type
    } = this.state;
    let userData = {};
    if (type === 1) {
      if (
        email === '' ||
        !emailValidation(email) ||
        password === ''
      ) {
        let msg;
        if (email === '') msg = 'Type your email address';
        else if (!emailValidation(email)) msg = 'Type your valid email address';
        else if (password === '') msg = 'Type your password';
        this.setState({
          isSnackBarOpen: true,
          snackBarMessage: msg,
          snackBarColor: STATUS_NEGATIVE_COLOR
        });
        return;
      }
      userData = {
        email: email,
        password: password
      };
      this.login(userData);
    } else {
      if (
        firstName === '' ||
        lastName === '' ||
        email === '' ||
        phoneNumber === '' ||
        !emailValidation(email) ||
        password === '' ||
        confirm === '' ||
        password !== confirm
      ) {
        let msg;
        if (firstName === '') msg = 'Type your first name';
        else if (lastName === '') msg = 'Type your last name';
        else if (email === '') msg = 'Type your email address';
        else if (phoneNumber === '') msg = 'Type your phone number';
        else if (!emailValidation(email)) msg = 'Type your valid email address';
        else if (password === '') msg = 'Type your password';
        else if (confirm === '') msg = 'Type your confirm';
        else if (password !== confirm) msg = 'Please input confirm password';
        this.setState({
          isSnackBarOpen: true,
          snackBarMessage: msg,
          snackBarColor: STATUS_NEGATIVE_COLOR
        });
        return;
      }
      userData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
      }
      axios
        .post(`${API_SERVER_URL}/api/user/register`, userData)
        .then(res => {
          console.log(res);
          if(res.data.success === true){
            this.login({email: email, password: password});
          } else {
            this.setState({
              isSnackBarOpen: true,
              snackBarMessage: res.data.error,
              snackBarColor: STATUS_NEGATIVE_COLOR,
            });
            return;
          }
        });
    }
  }

  render() {
    let {
      email,
      password,
      emailfocusOn,
      passwordfocusOn,
      firstName,
      firstNamefocusOn,
      lastName,
      lastNamefocusOn,
      phoneNumber,
      phoneNumberfocusOn,
      confirm,
      confirmfocusOn,
      remember,
      type,
      isSnackBarOpen,
      snackBarMessage,
      snackBarColor
    } = this.state;

    return (
      <S.DialogWrapper>
        <SnackBar
          isOpen={isSnackBarOpen}
          message={snackBarMessage}
          snackBarColor={snackBarColor}
          handleClose={() => this.setState({ isSnackBarOpen: false, snackBarMessage: '' })}
        />
        <S.Dialog>
          <div
            style={{ cursor: 'pointer', width: '100%', userSelect: 'none' }}
            onClick={() => this.props.setSignOpen(false)}
          >
            <img
              style={{ float: 'right', height: 25, transform: 'rotate(45deg)' }}
              src="/assets/img/svg/bottombar/arrow-plus.svg"
            />
          </div>
          <S.DialogTitle>
            {type === 1 ? 'Login' : 'Register'} your account
          </S.DialogTitle>
          <S.DialogContent>
            {type === 1
              ? 'We need to log you in to retrieve your saved projects.'
              : 'Register your DIY Design Space to save and recover your file at anytime.'}
          </S.DialogContent>
          {type === 1 ? (
            <S.DialogContentSelection>
              <S.StyledInputWrapper>
                <S.StyledInput
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  onFocus={e => this.setState({ emailfocusOn: true })}
                  onBlur={e => this.setState({ emailfocusOn: false })}
                  placeholder={emailfocusOn ? 'Enter Email' : 'Email'}
                  required
                />
                {(emailfocusOn || !!email.length) && (
                  <S.StyledInputHeadLabel
                    style={{ color: !emailfocusOn && TEXT_COLOR_NEUTRAL_2 }}
                  >
                    Email
                  </S.StyledInputHeadLabel>
                )}
                {!!email.length && (
                  <S.PlusImage
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ email: '' })}
                  />
                )}
              </S.StyledInputWrapper>
              <S.StyledInputWrapper style={{ flexDirection: 'column' }}>
                <S.DialogContent
                  style={{ padding: 'unset', textAlign: 'right' }}
                >
                  Forgot Password?
                </S.DialogContent>
                <S.StyledInput
                  value={password}
                  type="password"
                  onChange={e => this.setState({ password: e.target.value })}
                  onFocus={e => this.setState({ passwordfocusOn: true })}
                  onBlur={e => this.setState({ passwordfocusOn: false })}
                  maxLength={50}
                  placeholder={passwordfocusOn ? 'Enter Password' : 'Password'}
                  required
                />
                {(passwordfocusOn || !!password.length) && (
                  <S.StyledInputHeadLabel
                    style={{
                      color: !passwordfocusOn && TEXT_COLOR_NEUTRAL_2,
                      top: 9
                    }}
                  >
                    Password
                  </S.StyledInputHeadLabel>
                )}
                {!!password.length && (
                  <S.PlusImage
                    style={{ top: 27 }}
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ password: '' })}
                  />
                )}
              </S.StyledInputWrapper>
              <S.DialogContent
                style={{ paddingLeft: 'unset', paddingTop: 'unset' }}
              >
                or
              </S.DialogContent>
              <FormControlLabel
                style={{ alignItems: 'flex-start', marginBottom: 15 }}
                control={
                  <StyledMuiCheckbox
                    style={{ padding: 'unset', margin: '0 10px' }}
                    checked={remember}
                    onChange={() => this.setState({ remember: !remember })}
                    value={remember}
                  />
                }
                label={
                  <S.DialogContents style={{ fontSize: 13 }}>
                    Login with “<strong>One Time Password</strong>”
                  </S.DialogContents>
                }
              />
            </S.DialogContentSelection>
          ) : (
            <S.DialogContentSelection>
              <S.StyledInputWrapper>
                <S.StyledInput
                  id="first_name_input"
                  value={firstName}
                  onChange={e => this.setState({ firstName: e.target.value })}
                  onFocus={e => this.setState({ firstNamefocusOn: true })}
                  onBlur={e => this.setState({ firstNamefocusOn: false })}
                  placeholder={
                    firstNamefocusOn ? 'Enter first name' : 'First name'
                  }
                  required
                />
                {(firstNamefocusOn || !!firstName.length) && (
                  <S.StyledInputHeadLabel
                    style={{ color: !firstNamefocusOn && TEXT_COLOR_NEUTRAL_2 }}
                  >
                    First Name
                  </S.StyledInputHeadLabel>
                )}
                {!!firstName.length && (
                  <S.PlusImage
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ firstName: '' })}
                  />
                )}
              </S.StyledInputWrapper>
              <S.StyledInputWrapper>
                <S.StyledInput
                  value={lastName}
                  onChange={e => this.setState({ lastName: e.target.value })}
                  onFocus={e => this.setState({ lastNamefocusOn: true })}
                  onBlur={e => this.setState({ lastNamefocusOn: false })}
                  placeholder={
                    lastNamefocusOn ? 'Enter last name' : 'Last name'
                  }
                  required
                />
                {(lastNamefocusOn || !!lastName.length) && (
                  <S.StyledInputHeadLabel
                    style={{ color: !lastNamefocusOn && TEXT_COLOR_NEUTRAL_2 }}
                  >
                    Last Name
                  </S.StyledInputHeadLabel>
                )}
                {!!lastName.length && (
                  <S.PlusImage
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ lastName: '' })}
                  />
                )}
              </S.StyledInputWrapper>
              <S.StyledInputWrapper>
                <S.StyledInput
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  onFocus={e => this.setState({ emailfocusOn: true })}
                  onBlur={e => this.setState({ emailfocusOn: false })}
                  placeholder={
                    emailfocusOn ? 'Enter email address' : 'Email Address'
                  }
                  required
                />
                {(emailfocusOn || !!email.length) && (
                  <S.StyledInputHeadLabel
                    style={{ color: !emailfocusOn && TEXT_COLOR_NEUTRAL_2 }}
                  >
                    Email Address
                  </S.StyledInputHeadLabel>
                )}
                {!!email.length && (
                  <S.PlusImage
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ email: '' })}
                  />
                )}
              </S.StyledInputWrapper>
              <S.StyledInputWrapper>
                <S.StyledInput
                  value={phoneNumber}
                  onChange={e =>
                    this.setState({
                      phoneNumber:
                        e.target.validity.valid || e.target.value.length === 0
                          ? e.target.value
                          : this.state.phoneNumber
                    })
                  }
                  onFocus={e => this.setState({ phoneNumberfocusOn: true })}
                  onBlur={e => this.setState({ phoneNumberfocusOn: false })}
                  pattern={'[0-9]*'}
                  maxLength={50}
                  placeholder={
                    phoneNumberfocusOn ? 'Enter phone number' : 'Phone Number'
                  }
                  required
                />
                {(phoneNumberfocusOn || !!phoneNumber.length) && (
                  <S.StyledInputHeadLabel
                    style={{
                      color: !phoneNumberfocusOn && TEXT_COLOR_NEUTRAL_2
                    }}
                  >
                    Phone Number
                  </S.StyledInputHeadLabel>
                )}
                {!!phoneNumber.length && (
                  <S.PlusImage
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ phoneNumber: '' })}
                  />
                )}
              </S.StyledInputWrapper>
              <S.StyledInputWrapper>
                <S.StyledInput
                  value={password}
                  type="password"
                  onChange={e => this.setState({ password: e.target.value })}
                  onFocus={e => this.setState({ passwordfocusOn: true })}
                  onBlur={e => this.setState({ passwordfocusOn: false })}
                  maxLength={50}
                  placeholder={passwordfocusOn ? 'Enter Password' : 'Password'}
                  required
                />
                {(passwordfocusOn || !!password.length) && (
                  <S.StyledInputHeadLabel
                    style={{ color: !passwordfocusOn && TEXT_COLOR_NEUTRAL_2 }}
                  >
                    Password
                  </S.StyledInputHeadLabel>
                )}
                {!!password.length && (
                  <S.PlusImage
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ password: '' })}
                  />
                )}
              </S.StyledInputWrapper>
              <S.StyledInputWrapper>
                <S.StyledInput
                  value={confirm}
                  type="password"
                  onChange={e => this.setState({ confirm: e.target.value })}
                  onFocus={e => this.setState({ confirmfocusOn: true })}
                  onBlur={e => this.setState({ confirmfocusOn: false })}
                  maxLength={50}
                  placeholder={
                    confirmfocusOn
                      ? 'Enter Confirm Password'
                      : 'Confirm Password'
                  }
                  required
                />
                {(confirmfocusOn || !!confirm.length) && (
                  <S.StyledInputHeadLabel
                    style={{ color: !confirmfocusOn && TEXT_COLOR_NEUTRAL_2 }}
                  >
                    Confirm Password
                  </S.StyledInputHeadLabel>
                )}
                {!!confirm.length && (
                  <S.PlusImage
                    maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                    onClick={() => this.setState({ confirm: '' })}
                  />
                )}
              </S.StyledInputWrapper>
            </S.DialogContentSelection>
          )}
          <S.DialogAction>
            <S.StyledButton onClick={() => this._submit()}>
              {type === 1 ? 'Login' : 'Register'}
            </S.StyledButton>
            <S.StyledButton
              style={{
                border: 'unset',
                padding: 'unset',
                backgroundColor: 'unset',
                color: SECONDARY_PURPLE_COLOR,
                width: 'max-contet'
              }}
              onClick={() => this.setState({ type: (type + 1) % 2, email: '', password: '', firstName: '', lastName: '', confirm: '', phoneNumber: '' })}
            >
              {type === 1
                ? "Don't you have an account?"
                : 'Already have an account?'}
            </S.StyledButton>
          </S.DialogAction>
        </S.Dialog>
      </S.DialogWrapper>
    );
  }
}

Sign.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  content: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array
};

Sign.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
