import React from 'react';
import PropTypes from 'prop-types';
import { UNITS_LENGTH, UNIT_INCH, UNIT_CENTIMETER, DEFAULT_FONT_FAMILY, TEXT_COLOR_NEUTRAL_0 } from '../../constants';
import convert from 'convert-units';
import { FormLabel, FormNumberInput, FormSelect } from '../../components/style/export';
import { Map } from 'immutable';
import { toFixedFloat } from '../../utils/math';
import styled from 'styled-components';

const DistanceFloorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`

const DistanceFloorTitle = styled.span`
  margin-right: auto;
  width: 110px;
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 16px;
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 400;
  line-height: 15px;
  text-align: left;
`


export default function PropertyLengthMeasure({ value, onUpdate, onValid, configs, sourceElement, internalState, state }, { catalog }) {
  let _unit = value.get('_unit');
  let unit = state.getIn(['scene', 'layers', state.scene.selectedLayer, 'unit']);
  let length = value.get('length') || 0; // in centi
  let _length = value.get('_length') || length; // in _unit
  if (!_unit) {
    _unit = UNIT_INCH;
    _length = convert(length).from(UNIT_CENTIMETER).to(UNIT_INCH);
  }
  let type = value.get('type') || 'Base';
  let { hook, label, ...configRest } = configs;
  let update = (lengthInput, unitInput, type) => {
    let newLength = toFixedFloat(lengthInput);
    let merged = null;
    if(type === 0) {
      merged = value.merge({
        _length: newLength,
        _unit: unitInput,
        length: convert(newLength).from(unitInput).to(UNIT_CENTIMETER),
      });
    } else {
      merged = value.merge({
        _length: convert(newLength).from(UNIT_CENTIMETER).to(unitInput),
        _unit: unitInput,
        length: newLength,
      });
    }
    if (hook) {
      return hook(merged, sourceElement, internalState, state).then(val => {
        return onUpdate(val);
      });
    }
    return onUpdate(merged);
  };

  return (
    <DistanceFloorWrapper>
      <DistanceFloorTitle>{label}</DistanceFloorTitle>
      <FormNumberInput
        disabled={type==='Tall'}
        value={convert(_length).from('in').to(unit)}
        onChange={event => update(event.target.value, _unit, 0)}
        onValid={onValid}
        labelName={label}
        style={{paddingRight: `${unit.length * 20}px`}}
        {...configRest}
      />
    </DistanceFloorWrapper>
  );

}

PropertyLengthMeasure.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  configs: PropTypes.object.isRequired,
  sourceElement: PropTypes.object,
  internalState: PropTypes.object,
  state: PropTypes.object.isRequired
};

PropertyLengthMeasure.contextTypes = {
  catalog: PropTypes.object.isRequired
};
