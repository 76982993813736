import React from 'react';
import DownloadSummaryTemp from '../downloadSummaryTemp';
import * as constants from '../../../../../constants';
import Viewer2DDownLoad from './viewer2DDownLoad';

const Show2DView = ({props}) => {

  return (
    <DownloadSummaryTemp>
      <div style={{marginBottom:'10px'}}>
        <h1 style={{ fontWeight: '700' }}>{constants.TWO_D_FLOOR_PLAN}</h1>
        <div style={{ fontWeight: 'bold' }}>
          {constants.TECHNICAL_VIEW}
        </div>
      </div>
       <Viewer2DDownLoad state={props.state} catalog={props.catalog} width={393} height={350} />
    </DownloadSummaryTemp>
  );
};

export default Show2DView;
