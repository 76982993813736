import React, { useState } from 'react';

import * as S from './styles';
import * as MS from '../styles';

export default function ProductLine({ url, title, info, onClick, name, hascds }) {
  const [newstate, setNewstate] = useState(false)
  title = title.charAt(0).toUpperCase() + title.substr(1);
  let spanStyle = { fontSize: 11 };

  return (
    <S.LineWrapper style={{ padding: '5px 15px 8px' }}>
      {
        hascds === true ?
          <S.Control
            onClick={onClick}
            style={{ marginTop: 3 }}
          >
            <div style={{ width: '100%', display: 'flex' }}>
              <S.LineImage>
                <img width={90} height={90} name={name} src={url} style={{ objectFit: 'contain' }} />
              </S.LineImage>
              <div style={{ display: 'grid', marginLeft: '10px' }}>
                <h4 style={{ marginTop: '3px', marginBottom: '9px', overflowWrap: 'anywhere' }}><span style={{ fontSize: 12 }}>{title}</span></h4>
                <span style={spanStyle}>Width: {info.width}"</span>
                <span style={spanStyle}>Depth: {info.height}"</span>
                <span style={spanStyle}>Height: {info.tall}"</span>

                <S.ControlIcon key={name}>
                  <img width={72} name={name} src={'/assets/img/svg/toolbar/add_button.svg'} />
                  <MS.ButtonTitle style={{ top: "37%", fontSize: 12 }}>
                    ADD
                  </MS.ButtonTitle>
                </S.ControlIcon>

              </div>
            </div>
          </S.Control > :
          <S.UnControl
            // onClick={onClick}
            style={{ marginTop: 3 }}
          >
            <div style={{ width: '100%', display: 'flex' }}>
              <S.LineImage>
                <img width={90} height={90} name={name} src={url} style={{ objectFit: 'contain' }} />
              </S.LineImage>
              <div style={{ display: 'grid', marginLeft: '10px' }}>
                <h4 style={{ marginTop: '3px', marginBottom: '9px', overflowWrap: 'anywhere' }}><span style={{ fontSize: 12 }}>{title}</span></h4>
                <span style={spanStyle}>Width: {info.width}"</span>
                <span style={spanStyle}>Depth: {info.height}"</span>
                <span style={spanStyle}>Height: {info.tall}"</span>
              </div>
            </div>
          </S.UnControl >
      }
    </S.LineWrapper>
  )
}
