import React, { useState } from 'react';

import * as S from './styles';
import * as MS from '../styles';

export default function PrimaryDoorStyle({ url, title, onClick }) {
  if (title !== null)
    title = title.charAt(0).toUpperCase() + title.substr(1);

  return (
    <div style={{ height: '100%' }}>
      <h3 style={{
        fontSize: 12,
        padding: "8px 15px",
        backgroundColor: "rgb(47, 47, 47)",
        margin: 0,
        display: "flex",
        height: 30,
        justifyContent: "start",
        color: "rgb(255, 255, 255)"
      }}>
        <span> Current Door Style </span>
      </h3>
      <S.PrimayDoorStyleLineWrapper id="primarywrapper" style={{ padding: '5px 15px 8px', display: 'grid' }}>
        {title != null && <h4 style={{ marginTop: '3px', marginBottom: '9px', justifySelf: 'center' }}><strong>{title}</strong></h4>}
        {title != null && <img style={{ justifySelf: 'center' }} name={title} width={'90px'} height={'155px'} src={url} />}
        {/* <p> <strong>Collection :</strong> Brilliant White </p> */}
        <div>
          <p> <strong>Description: </strong></p>
          <p>
            Door selection for all cabinets. <br />
            To browse the entire collection of doors see below.
          </p>
        </div>
      </S.PrimayDoorStyleLineWrapper>
      <div style={{ marginTop: title == null ? 125 : 40 }}>
        <S.Control
          onClick={onClick}
        >
          <S.ControlIcon key={title}>
            <img width={'100px'} name={title} src={'/assets/img/svg/toolbar/style_change_button.svg'} />
            <MS.ButtonTitle style={{ top: "42%", fontSize: 11 }}>
              CHANGE
            </MS.ButtonTitle>
          </S.ControlIcon>
        </S.Control>
      </div>
    </div>
  )
}
