import React, {useState} from 'react';
import State3DDownload from "./ruler-utils-download/state3DDownload";
import Viewer3DRightElevationDownload from "./viewer3DRightElevationDownload";

let k = 0;
const ViewerRightElevationDownLoad = ({state,catalog}) => {
  const [mode, setMode] = useState('');
  if(mode !== state.get('mode')){
    k++;
    setMode(state.get('mode'));
  }
  const width = 393, height = 250;

  return (
    <div style={{position:'relative', minHeight:350}}>
      <svg id="elevation" width={width} height={height} style={{position:"absolute" }}>
        <g transform={`translate(${width/2}, ${height/2})`}>
          <State3DDownload state={state} catalog={catalog} width={width} height={height}/>
        </g>
      </svg>
      <Viewer3DRightElevationDownload key={k} state={state} width={width} height={height}/>
    </div>
  );
};

export default ViewerRightElevationDownLoad;
