export default {
  '+ Add Horizontal Giude': '+ Add Horizontal Giude',
  '+ Add Vertical Giude': '+ Add Vertical Giude',
  '2D View': '2D View',
  '3D First Person': '3D First Person',
  '3D View': '3D View',
  'Floor Plan': 'Floor Plan',
  'Cabinets': 'Cabinets',
  'Door Style': 'Door Style',
  'Appliances': 'Appliances',
  'Styles': 'Styles',
  'Room Shape': 'Room Shape',
  'Settings': 'Settings',
  altitude: 'altitude',
  Altitude: 'Altitude',
  Areas: 'Areas',
  Cancel: 'Cancel',
  Catalog: 'Catalog',
  'Chain selected Elements to Group': 'Chain selected Elements to Group',
  Circular: 'Circular',
  color: 'color',
  'Configure layer': 'Configure layer',
  'Configure project': 'Configure project',
  Confirm: 'Confirm',
  Copy: 'Copy',
  'Delete group and all Elements': 'Delete group and all Elements',
  'Delete layer': 'Delete layer',
  Delete: 'Delete',
  'Elements on layer {0}': 'Elements on layer {0}',
  Elements: 'Elements',
  'Get Screenshot': 'Get Screenshot',
  'Group [{0}]': 'Group [{0}]',
  "Group's Barycenter": "Group's Barycenter",
  "Group's Elements": "Group's Elements",
  Groups: 'Groups',
  Guides: 'Guides',
  height: 'height',
  Holes: 'Holes',
  Horizontal: 'Horizontal',
  Items: 'Items',
  'Last Selected': 'Last Selected',
  'Layer config': 'Layer config',
  Layer: 'Layer',
  Layers: 'Layers',
  Length: 'Length',
  Lines: 'Lines',
  'Load project': 'Load project',
  'Mouse X Coordinate': 'Mouse X Coordinate',
  'Mouse Y Coordinate': 'Mouse Y Coordinate',
  Name: 'Name',
  'New Empty Group': 'New Empty Group',
  'New Group from selected': 'New Group from selected',
  'New layer': 'New layer',
  'New project': 'New project',
  opacity: 'opacity',
  'Open catalog': 'Open catalog',
  order: 'order',
  Pan: 'Pan',
  Paste: 'Paste',
  'Project config': 'Project config',
  'Properties: [{0}] {1}': 'Properties: [{0}] {1}',
  Prototype: 'Prototype',
  Reset: 'Reset',
  Rotation: 'Rotation',
  'Save project': 'Save project',
  Save: 'Save',
  'Scene Zoom Level': 'Scene Zoom Level',
  'Search Project': 'Search Project',
  'Snap to Grid': 'Snap to Grid',
  'Snap to Guide': 'Snap to Guide',
  'Snap to Line': 'Snap to Line',
  'Snap to Point': 'Snap to Point',
  'Snap to Segment': 'Snap to Segment',
  texture: 'texture',
  thickness: 'thickness',
  'Toggle Group Visibility': 'Toggle Group Visibility',
  "Un-chain all Group's Elements and remove Group":
    "Un-chain all Group's Elements and remove Group",
  'Un-chain Element from Group': 'Un-chain Element from Group',
  'Undo (CTRL-Z)': 'Undo (CTRL-Z)',
  Unselect: 'Unselect',
  Vertical: 'Vertical',
  width: 'width',
  'Would you want to start a new Project?':
    'Would you want to start a new Project?',
  'Zoom in': 'Zoom in',
  'Zoom out': 'Zoom out',
  'Overall Style': 'Overall Style',
  'Make Floor Plan': 'Make Floor Plan',
  'Add Cabinets': 'Add Cabinets',
  'Add Appliances': 'Add Appliances',
  'Finishing Touch': 'Finishing Touch',
  'Select Door Style': 'Select Door Style',
  'Review & Quote': 'Review & Quote',
  'Select All': 'Select All',
  'Edit': 'Edit',
  'UNDO': 'UNDO',
  'REDO': 'REDO',
  'Take Picture': 'Take Picture',
  'Duplicate': 'Duplicate',
  'Remove': 'Remove',
  'New Project': 'New Project',
  'Width': 'Width',
  'Depth': 'Depth',
  'Height': 'Height'
};
